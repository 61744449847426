import {ReduxStateStatus, UNSET} from 'constants/reduxStatuses'
import {
  DELETE_UPLOADED_DOCUMENTS,
  RESET_DOCUMENTS,
  SET_DOCUMENTS_LOADING,
  SET_UPLOADED_DOCUMENTS
} from 'constants/actionTypes'

export type UploadedDocuments = {
  name: string
  loading: boolean
  storageKey?: string
}

type DocumentsState = {
  status: ReduxStateStatus
  error?: null
  uploadedDocuments: Array<UploadedDocuments>
}

const initialState: DocumentsState = {
  status: UNSET,
  error: null,
  uploadedDocuments: []
}

type DocumentAction =
  | {
      type: typeof SET_DOCUMENTS_LOADING
    }
  | {type: typeof RESET_DOCUMENTS}
  | {
      type: typeof SET_UPLOADED_DOCUMENTS
      payload: UploadedDocuments
    }
  | {
      type: typeof DELETE_UPLOADED_DOCUMENTS
      payload: string
    }

const documentsReducer = (state = initialState, action: DocumentAction): DocumentsState => {
  const {type} = action

  switch (type) {
    case SET_UPLOADED_DOCUMENTS: {
      let result = []
      const updatedIndex = state?.uploadedDocuments?.findIndex(
        document => document.name === action.payload.name
      )
      if (updatedIndex !== -1) {
        result = [...state.uploadedDocuments]
        result[updatedIndex] = action.payload
      } else {
        result = [...state.uploadedDocuments, action.payload]
      }
      return {
        ...state,
        uploadedDocuments: result
      }
    }
    case DELETE_UPLOADED_DOCUMENTS: {
      return {
        ...state,
        uploadedDocuments:
          state.uploadedDocuments.filter(document => document.name !== action.payload) ?? []
      }
    }
    case RESET_DOCUMENTS:
      return initialState
    default:
      return state
  }
}

export default documentsReducer
