// Style
import styled from 'styled-components'

// Core
import React, {Fragment, useEffect} from 'react'
import {useSelector} from 'react-redux'

// Components and others
import {CheckCircle, Error} from '@material-ui/icons'
import {openModal} from 'actions/modalActions'
import Address from 'components/Address'
import Button from 'components/Button'
import MetaDataList from 'components/MetaDataList'
import Tabs from 'components/Tabs'
import Tooltip from 'components/Tooltip'
import moment from 'moment'
import {propertyHeroDataSelector} from 'selectors'
import {useAppDispatch} from 'services/store'
import type {OpsOrderType, Revision} from 'types/orderTypes'
import DropdownButton from './DropdownButton'
import DropdownButtonExtend from './DropdownButtonExtend'
import {isAVMOrder, shouldShowOrdersTab} from 'services/orderDetailsServices'

export type OrderDetailsTabType = 'report' | 'status'
const TabsData: {label: string; value: OrderDetailsTabType}[] = [
  {label: 'Report', value: 'report'},
  {label: 'Order Details', value: 'status'}
]

type OrderDetailsHeaderProps = {
  order: OpsOrderType
  activeTab?: OrderDetailsTabType
  onActiveTabChange: (tab: OrderDetailsTabType) => void
}
const OrderDetailsHeader = ({order, activeTab, onActiveTabChange}: OrderDetailsHeaderProps) => {
  const dispatch = useAppDispatch()
  const propertyHeroData = useSelector(propertyHeroDataSelector)
  const isCompleted = order.status?.orderStatus === 'COMPLETED'
  const defaultTab = isCompleted ? 'report' : 'status'
  const isRevision = order.status?.revision
  const isCompletedorisRevision = isCompleted || isRevision
  const shouldShowTab = shouldShowOrdersTab(order)

  useEffect(() => {
    if (!activeTab) {
      onActiveTabChange(defaultTab)
    }
  }, [activeTab, defaultTab, onActiveTabChange])

  const handleRevision = async () => {
    dispatch(
      openModal({
        modalType: 'CHANGE_ORDER_STATUS_MODAL',
        mode: 'REVISION',
        opsOrderId: order.opsOrderId
      })
    )
  }

  return (
    <OrderDetailsHeader.Styled $hasTab={shouldShowTab}>
      <div className='details-wrap'>
        <div>
          <h2 className='address'>
            <Address address={propertyHeroData.address} sqaPrefix='header' />
          </h2>
          <MetaDataList metaData={propertyHeroData.metaData} />
        </div>

        <div className='button-container'>
          {isCompletedorisRevision ? (
            <Fragment>
              {!isAVMOrder(order) && (
                <Button
                  title='Request Revision'
                  variant='secondary'
                  size='large'
                  onClick={handleRevision}
                />
              )}
              <DropdownButtonExtend />
            </Fragment>
          ) : (
            <DropdownButton />
          )}
        </div>
      </div>
      {shouldShowTab && (
        <TabsContainer>
          <Tabs
            tabs={TabsData}
            value={activeTab || defaultTab}
            onChange={(e, tab) => onActiveTabChange(tab)}
          />
          {order.status?.revision && (
            <OrderDetailsHeader.RevisionTooltip revision={order.status?.revision} />
          )}
        </TabsContainer>
      )}
    </OrderDetailsHeader.Styled>
  )
}

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    margin: 0;
    font-weight: 500;
  }

  .denied,
  .revised {
    display: flex;
    column-gap: 4px;
    align-items: center;

    .portal-MuiSvgIcon-root {
      font-size: 1rem;
    }
  }

  .denied .portal-MuiSvgIcon-root {
    color: ${({theme}) => theme.colors.ruby.base};
  }

  .revised .portal-MuiSvgIcon-root {
    color: ${({theme}) => theme.colors.emerald.base};
  }

  .requested {
    h6 {
      color: ${({theme}) => theme.colors.grayscale.gray};
    }

    border-color: ${({theme}) => theme.colors.stone.base};
  }
`

const DATE_FORMAT = 'MMM DD, YYYY'
OrderDetailsHeader.RevisionTooltip = ({revision}: {revision: Revision}) => {
  if (revision.revisionDenied) {
    return (
      <div className='denied'>
        <Error />
        {revision.denialReason && (
          <Tooltip text={revision.denialReason}>
            <h6>Request denied on {moment(revision.deniedDate).format(DATE_FORMAT)}</h6>
          </Tooltip>
        )}
      </div>
    )
  } else if (revision.revisionCompleted) {
    return (
      <div className='revised'>
        <CheckCircle />
        <h6>Report revised on {moment(revision.revisedDate).format(DATE_FORMAT)}</h6>
      </div>
    )
  } else {
    return (
      <Tooltip
        text={
          revision.revisionReason ||
          'Requests are usually processed within two business days. We will email you when an update is available.'
        }
        className='requested'
      >
        <h6>Revision requested on {moment(revision.requestedDate).format(DATE_FORMAT)}</h6>
      </Tooltip>
    )
  }
}

OrderDetailsHeader.Styled = styled.div<{$hasTab: boolean}>`
  background: #fff;
  padding: 20px 40px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  padding-bottom: ${({$hasTab}) => ($hasTab ? 0 : '20px')};

  .details-wrap {
    display: flex;
    justify-content: space-between;
  }

  .portal-MuiTabs-root {
    margin-top: 8px;
  }

  .meta-data {
    font-size: 0.875rem;
  }

  .address {
    font-size: 1.375rem;
    margin-bottom: 2px;
  }

  .button-container {
    .icon-more {
      font-size: 1rem;
      margin-left: 4px;
    }
  }
`

export default OrderDetailsHeader
