// Style
import styled from 'styled-components'

// Core
import {CircularProgress} from '@material-ui/core'
import React, {Fragment, memo, useEffect, useState, useRef} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation, useParams} from 'react-router-dom'

// Components and others
import {
  getOrderByOpsOrderId,
  resetOrdersByOpsOrderId,
  updateOrderStatusByOpsOrderId
} from 'actions/ordersActions'
import OrderDetailsStatus from 'components/OrderDetailsStatus'
import type {OrderDetailsTabType} from 'components/OrderDetailsHeader'
import OrderDetailsHeader from 'components/OrderDetailsHeader'
import {ERROR, LOADING, UNSET} from 'constants/reduxStatuses'
import {orderByOpsOrderIdSelector, orderByOpsOrderIdStatusSelector} from 'selectors'
import {useAppDispatch} from 'services/store'
import PDFRenderer from 'components/PDFRenderer'
import {useDownloadDocumentsMap} from 'hooks/useDownloadDocumentsMap'
import {opsApi, websocketUrl} from 'services/apis'

// Third parties
import classnames from 'classnames'
import {usePropertyData} from 'hooks/usePropertyData'
import {resetPropertyState} from 'actions/propertyActions'
import {DocumentUrlResponse} from 'types/orderTypes'
import {isAVMOrder, shouldShowOrdersTab} from 'services/orderDetailsServices'
import useHandleOrderByOpsOrderIdStatusError from 'hooks/useHandleOrderByOpsOrderIdStatusError'
import {w3cwebsocket as W3CWebSocket} from 'websocket'
import {trackError} from 'services/sentryServices'

const PDFRendererMomoized = memo(PDFRenderer)

const OrderDetails = () => {
  const dispatch = useAppDispatch()
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const order = useSelector(orderByOpsOrderIdSelector)
  const orderByOpsOrderIdStatus = useSelector(orderByOpsOrderIdStatusSelector)
  const [activeTab, setActiveTab] = useState<OrderDetailsTabType>()
  const [pdfUrl, setPdfUrl] = useState('')
  const [isPdfLoading, setIsPdfLoading] = useState(false)
  const {data: documentMap, loading: isDocumentsLoading} = useDownloadDocumentsMap()
  const isDocumentAndPdfLoading = isDocumentsLoading || isPdfLoading
  const isLoading =
    orderByOpsOrderIdStatus === LOADING ||
    orderByOpsOrderIdStatus === UNSET ||
    isDocumentAndPdfLoading
  const suppliedAddress = order.suppliedAddress
  usePropertyData({
    addressLine1: suppliedAddress?.street,
    city: suppliedAddress?.city,
    state: suppliedAddress?.state,
    zip: suppliedAddress?.zipcode
  })
  useHandleOrderByOpsOrderIdStatusError()
  const isClearAVMOrder = isAVMOrder(order)
  const shouldShowTab = shouldShowOrdersTab(order)
  const hasDeliverablePdf = documentMap?.DELIVERABLE_PDF
  const deliverablePDFDownloadURL = `orders/${opsOrderId}/documents/DELIVERABLE_PDF/url`
  const orderPlacementWebSocketRef = useRef<W3CWebSocket | null>(null)
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const fetchReportPdfUrl = async () => {
      setIsPdfLoading(true)

      const {url: pdfUrl} = await opsApi.get<DocumentUrlResponse>(deliverablePDFDownloadURL)
      setPdfUrl(pdfUrl)
      setIsPdfLoading(false)
    }

    hasDeliverablePdf && fetchReportPdfUrl()
  }, [deliverablePDFDownloadURL, hasDeliverablePdf, opsOrderId])

  useEffect(() => {
    const getDownloadDocumentsUrl = async () => {
      setIsPdfLoading(true)
      const {url} = await opsApi.get<DocumentUrlResponse>(deliverablePDFDownloadURL)
      setPdfUrl(url)
      setIsPdfLoading(false)
    }
    isClearAVMOrder && getDownloadDocumentsUrl()
  }, [deliverablePDFDownloadURL, isClearAVMOrder, opsOrderId])

  useEffect(() => {
    if (orderByOpsOrderIdStatus === ERROR) {
      // we use history.replace here to ensure that clicking the Go Back button on the
      // NotFound will actually take the user to the previous route and avoids the continuous
      // loop that occurs if we use history.push instead
      history.replace({
        pathname: location.pathname,
        state: {
          error: true
        }
      })
    }
  }, [history, location.pathname, orderByOpsOrderIdStatus])

  useEffect(() => {
    dispatch(getOrderByOpsOrderId(opsOrderId)).then(() => {
      try {
        if (orderPlacementWebSocketRef.current === null && opsOrderId && activeTab === 'status') {
          orderPlacementWebSocketRef.current = new W3CWebSocket(
            `${websocketUrl}?opsOrderId=${opsOrderId}`
          )

          orderPlacementWebSocketRef.current.onmessage = event => {
            if (typeof event.data === 'string') {
              const orderStatus = JSON.parse(event.data).payload.status
              dispatch(updateOrderStatusByOpsOrderId(orderStatus))
            }
          }

          orderPlacementWebSocketRef.current.onerror = error => trackError(error)
        }
      } catch (error) {
        trackError(error as Error)
      }
    })
    return () => {
      dispatch(resetOrdersByOpsOrderId())

      try {
        orderPlacementWebSocketRef.current?.close()
      } catch (error) {
        trackError(error as Error)
      }
    }
  }, [dispatch, opsOrderId, activeTab])

  useEffect(() => {
    return () => {
      dispatch(resetPropertyState())
    }
  }, [dispatch])

  return (
    <OrderDetails.Styled
      className={classnames({isLoading, hasReport: activeTab === 'report'})}
      hasTab={shouldShowTab}
    >
      {isLoading ? (
        <CircularProgress size={100} />
      ) : (
        <Fragment>
          <OrderDetailsHeader
            order={order}
            activeTab={activeTab}
            onActiveTabChange={setActiveTab}
          />
          {activeTab === 'status' && <OrderDetailsStatus order={order} />}
        </Fragment>
      )}

      <div
        className={classnames('pdf-renderer-container', {
          hide: isLoading || activeTab !== 'report'
        })}
      >
        <PDFRendererMomoized url={pdfUrl} />
      </div>
    </OrderDetails.Styled>
  )
}

OrderDetails.Styled = styled.div<{hasTab: boolean}>`
  display: flex;
  flex-direction: column;

  &.isLoading {
    min-height: 100%;
    align-items: center;
    justify-content: center;
  }

  &.hasReport {
    overflow: hidden;
    height: 100%;
  }

  .pdf-renderer-container {
    display: block;

    &.hide {
      display: none;
    }

    .pdf-renderer {
      /** minus header size */
      height: ${props => (props.hasTab ? `calc(100vh - 250px)` : `calc(100vh - 220px)`)};
    }
  }
`

export default OrderDetails
