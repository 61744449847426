import {useSelector} from 'react-redux'
import {ordersPerPageSelector} from 'selectors/ordersSelectors'
import {createGetUrlWithCustomerKey} from 'services/ordersServices'
import {opsApi} from 'services/apis'
import useSWR from 'swr/immutable'
import {customerKeySelector} from 'selectors/customerSelectors'
import type {GetOrderListResponse} from 'types/orderTypes'

export const useOrders = () => {
  const ordersPerPage = useSelector(ordersPerPageSelector)
  const customerKey = useSelector(customerKeySelector)
  const getUrl = createGetUrlWithCustomerKey(
    `orders?index=recent&scanReverse=false&count=${ordersPerPage}`,
    customerKey
  )
  const {data, error, isValidating} = useSWR(getUrl, async () =>
    opsApi.get<GetOrderListResponse>(getUrl)
  )
  return {data: data!, error, isValidating}
}
