import CDAPromo from 'assets/promos/CDA.png'
import ClearAvmPromo from 'assets/promos/clearavm.png'
import ClearPropPromo from 'assets/promos/clearprop_promo.png'
import type {ChipProps} from 'components/Chip/Chip'
import {ProductGroupType} from 'types/productTypes'

export const CLEARPROP = 'CLEARPROP'
export const CLEAR_AVM_FULLY_SUPPORTED = 'CLEAR_AVM_FULLY_SUPPORTED'
export const CLEAR_AVM_VALUES_ONLY = 'CLEAR_AVM_VALUES_ONLY'
export const CDA = 'CDA'
export const CDA_MLS = 'CDA_MLS'
export const AVM = 'AVM'

export type ProductData = {
  title: string
  subtitle: string
  landingDescription: string
  popDescription: string
  learnMoreLink?: string
  isProductActive: boolean
  icon: string
  startingPrice?: number
  productColor: ChipProps['color']
  promoImg: string
  name: string
  // to check if the product is available.
  // since now we only have 2 product, use only one flag to for simplicity.
  // if the logic gets complex later, change this to a isAvailable() function
  needPID?: boolean
}

export const productGroupDictionary: {[key in ProductGroupType]: ProductData} = {
  CLEARPROP: {
    title: 'ClearProp®',
    subtitle: 'An interactive property analysis and valuation tool',
    landingDescription:
      'An interactive tool that brings data on a property together to quickly perform your own cost-effective valuations.',
    popDescription:
      'Perform property valuations quickly using data-rich sources, ranked comps, market history, ClearAVM™, and more.',
    isProductActive: true,
    icon: 'home',
    productColor: 'turquoise' as ChipProps['color'],
    promoImg: ClearPropPromo,
    startingPrice: 15,
    name: 'ClearProp®',
    needPID: true
  },
  AVM: {
    title: 'ClearAVM\u2122',
    subtitle: 'Instant property value insights',
    landingDescription:
      'A lending-grade automated valuation model with near-real-time data that provides an instant property value.',
    popDescription:
      'ClearAVM™ is designed to help investors, lenders, and regulators make lending decisions, confirm values, invest confidently, and more.',
    learnMoreLink:
      'https://www.clearcapital.com/products/clearavm/?utm_source=portal&utm_medium=homepage&utm_content=clearavm_learn_more',
    isProductActive: true,
    icon: 'requestQuote',
    productColor: 'amethyst' as ChipProps['color'],
    promoImg: ClearAvmPromo,
    name: 'ClearAVM\u2122',
    startingPrice: 5
  },
  CDA: {
    title: 'Collateral Desktop Analysis (CDA®)',
    subtitle: 'USPAP-compliant appraisal desktop review',
    landingDescription:
      'A USPAP-compliant appraisal desktop review that re-evaluates the original appraisal to enable pre-funding certainty.',
    popDescription:
      'CDA removes pre-funding uncertainty and supports due diligence by re-evaluating an appraisal to deliver a comprehensive loan file.',
    learnMoreLink:
      'https://www.clearcapital.com/products/collateral-desktop-analysis-cda/?utm_source=portal&utm_medium=property_page&utm_content=cda_learn_more',
    isProductActive: true,
    icon: 'verified_user',
    productColor: 'cobalt',
    promoImg: CDAPromo,
    name: 'CDA®',
    startingPrice: 90
  }
}

export const productDataDictionaryHomePage: {[key in ProductGroupType]: ProductData} = {
  ...productGroupDictionary,
  CLEARPROP: {
    ...productGroupDictionary.CLEARPROP,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearprop/?utm_source=portal&utm_medium=homepage&utm_content=cp_learn_more'
  },
  CDA: {
    ...productGroupDictionary.CDA,
    learnMoreLink:
      'https://www.clearcapital.com/products/collateral-desktop-analysis-cda/?utm_source=portal&utm_medium=homepage&utm_content=cda_learn_more'
  }
}

export const productDataDictionaryNonHomePage: {[K in ProductGroupType]: ProductData} = {
  ...productGroupDictionary,
  CLEARPROP: {
    ...productGroupDictionary.CLEARPROP,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearprop/?utm_source=portal&utm_medium=property_page&utm_content=cp_learn_more'
  },
  CDA: {
    ...productGroupDictionary.CDA,
    learnMoreLink:
      'https://www.clearcapital.com/products/collateral-desktop-analysis-cda/?utm_source=portal&utm_medium=property_page&utm_content=cda_learn_more'
  },
  AVM: {
    ...productGroupDictionary.AVM,
    learnMoreLink:
      'https://www.clearcapital.com/products/clearavm/?utm_source=portal&utm_medium=property_page&utm_content=clearavm_learn_more'
  }
}

export const productGroupArray = [CLEARPROP, CDA, AVM] as const

export const PRODUCT_UNAVAILABLE_MESSAGE =
  'This product is currently unavailable for the address provided.  Review the address for errors or contact support@clearcapital.com for assistance.'
