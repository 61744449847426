export const REQUIRED_FIELD_MSG = 'This field is required.'
export const INVALID_FIRST_NAME_MESSAGE = 'First Name is not valid.'
export const INVALID_LAST_NAME_MESSAGE = 'Last Name is not valid.'
export const REQUIRED_COLLECTION_MSG =
  'This collection is required. At least one value must be selected.'
export const INVALID_EMAIL_MSG = 'Email is not valid.'
export const INVALID_PHONE_MSG = 'Phone number is not valid.'
export const REQUIRED_FILE_MSG = 'No file selected.'
export const INVALID_PASSWORD_MSG = 'Password must meet all requirements.'
export const INVALID_ACCESS_CODE_MSG = 'Verification code incorrect or expired.'
export const MOBILE_NUMBER_DUPLICATE_ERROR =
  'Unable to validate provided phone number. Enter a different phone number or contact support.'
export const ADDRESS_SEARCH_INPUTS_REQUIRED =
  'Address search requires street address, city, state and zip code.  Fill in the missing fields and try again.'
export const REQUIRED_DELIVERY_OPTION = 'You must select a delivery option.'
export const REQUIRED_PAYMENT_OPTION = 'You must select a payment option.'
export const MAX_STRING_LENGTH_START = 'This field should be no longer than'
export const MAX_STRING_LENGTH_END = 'characters.'
export const MISMATCHED_FILE_EXT_START = 'File extension must be'
export const REQUIRED_DOCUMENTS = 'You must provide all required documents.'
export const REQUIRED_UPLOAD_DOCUMENT = 'You must select at least one document.'
export const MAX_FILE_SIZE = 15 * 1000 * 1000
export const FILE_SIZE_TEXT = `${MAX_FILE_SIZE / 1000 / 1000}MB`
export const FILE_SIZE_EXCEEDS_LIMIT_MESSAGE = `File size exceeds limit. Maximum file size is ${FILE_SIZE_TEXT}`
export const FETCH_ERROR_MESSAGE_PREFIX = 'Api Error'
