import type {OrderFormConfigByProductGroupType} from 'types/productTypes'
import {productGroupDictionary} from 'constants/productCardConstants'

export const ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP: OrderFormConfigByProductGroupType = {
  CLEARPROP: {
    productGroup: 'CLEARPROP',
    image: productGroupDictionary.CLEARPROP.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: false
        }
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  CDA: {
    productGroup: 'CDA',
    image: productGroupDictionary.CDA.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: true
        }
      },
      LOAN_DETAILS: {
        inputs: {
          loanNumber: true,
          loanType: false,
          loanPurpose: true,
          borrower: true,
          estimatedClosingDate: false,
          caseNumber: false,
          duCaseNumber: false,
          lpaCaseNumber: false,
          primaryGse: false,
          lenderOrClientName: true,
          investor: true
        }
      },
      UPLOAD_DOCUMENTS: {
        requiredDocuments: ['APPRAISAL']
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  },
  AVM: {
    productGroup: 'AVM',
    image: productGroupDictionary.AVM.promoImg,
    sections: {
      PRODUCT_DETAILS: {
        inputs: {
          productCode: true,
          trackingId: true,
          orderInstructions: false
        }
      },
      DELIVERY_OPTIONS: true,
      PAYMENT_OPTIONS: true
    }
  }
}

export const ORDER_FORM_SQA_PREFIX = 'order-form'
export const STRIPE_3D_SECURE_ERROR = 'Stripe3dSecureError'

export const MAXIMUM_PRODUCTS_GROUPS_REQUEST_RETRY_FAILED = 6
