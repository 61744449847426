import {SUPPORT_EMAIL, SUPPORT_PHONE} from 'constants/appConstants'

export type ActionType =
  | 'FORGOT_PASSWORD'
  | 'RESET_PASSWORD'
  | 'LOG_IN'
  | 'SIGN_UP'
  | 'UPLOAD_DOCUMENT'

const ERROR_MESSAGE_END = `If the problem persists, contact Customer Support at ${SUPPORT_EMAIL} or ${SUPPORT_PHONE}.`

export const ACTION_ERROR_MESSAGE_END = `failed. ${ERROR_MESSAGE_END}`

export const ACTION_LIMIT_EXCEEDED_ERROR_MESSAGE_END = `attempts reached. Wait 1 minute before trying again.`

export const ACTION_ERROR_MESSAGE_PREFIX_BY_TYPE: Record<ActionType, string> = {
  FORGOT_PASSWORD: 'Forgot password',
  RESET_PASSWORD: 'Reset password',
  LOG_IN: 'Log in',
  SIGN_UP: 'Sign up',
  UPLOAD_DOCUMENT: 'Uploading document'
}

export const ACTION_LOG_IN_FAILURE_MESSAGE =
  'Log in failed. Check your email and password and try again.'

export const SESSION_EXPIRED_MESSAGE = 'Your session has expired. Log in to continue.'

export const ADDRESS_SEARCH_ERROR =
  'Unable to load the provided address. Check the address and try again.'

export const GET_PRODUCTS_ERROR = `Failed to retrieve products. Please refresh and try ordering again. ${ERROR_MESSAGE_END}`

export const INVITE_USER_SUCCESS_MESSAGE =
  'User successfully added to account. Invitation email has been sent.'

export const INVITE_USER_FAILURE_MESSAGE = `Failed to add user. ${ERROR_MESSAGE_END}`

export const RESEND_INVITE_SUCCESS_MESSAGE = 'Invitation email has been sent.'

export const RESEND_INVITE_FAILURE_MESSAGE = `Failed to invite user. ${ERROR_MESSAGE_END}`

export const UPDATE_CUSTOMER_USER_SUCCESS_MESSAGE = 'User successfully updated.'

export const UPDATE_CUSTOMER_USER_ERROR_MESSAGE = `Failed to update user. ${ERROR_MESSAGE_END}`

export const UPDATE_USER_PROFILE_MESSAGE = `Failed to update profile. ${ERROR_MESSAGE_END}`

export const DOWNLOAD_FAILED_MESSAGE = `Download failed. ${ERROR_MESSAGE_END}`

export const REQUEST_HOLD_FAILURE_MESSAGE = `Hold request failed to send. ${ERROR_MESSAGE_END}`

export const REMOVE_HOLD_FAILURE_MESSAGE = `Failed to request hold removal. ${ERROR_MESSAGE_END}`

export const REVISION_REQUEST_FAILURE_MESSAGE = `Revision request failed to send. ${ERROR_MESSAGE_END}`

export const CANCEL_ORDER_FAILURE_MESSAGE = `Cancellation request failed to send. ${ERROR_MESSAGE_END}`

export const PLACE_ORDER_FAILURE_MESSAGE = `Failed to place order. ${ERROR_MESSAGE_END}`

export const LOGGED_OUT_INACTIVITY_MESSAGE =
  'You have been automatically logged out due to inactivity.'
