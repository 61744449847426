// Core
import {ButtonProps, MenuProps} from '@material-ui/core'
import {ExpandMore} from '@material-ui/icons'
import React, {Fragment, useState} from 'react'
import {useParams} from 'react-router-dom'

// Components
import Button from 'components/Button'
import {useDownloadDocumentsMap} from 'hooks/useDownloadDocumentsMap'
import {CDADownloadList, getCDADownloadMenuItems} from 'services/pdfExportServices'
import DownloadMenu from './DownloadMenu'
import {isCDAOrder} from 'services/orderDetailsServices'
import {orderByOpsOrderIdSelector} from 'selectors'
import {useSelector} from 'react-redux'

const DropdownButton = () => {
  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null)
  const handleOpen: ButtonProps['onClick'] = se => setAnchorEl(se.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const {data: documentMap} = useDownloadDocumentsMap()
  const order = useSelector(orderByOpsOrderIdSelector)

  const menuItems = isCDAOrder(order)
    ? getCDADownloadMenuItems(CDADownloadList, opsOrderId, documentMap)
    : []

  return (
    <Fragment>
      <Button
        title={
          <Fragment>
            <span>Download</span>
            <ExpandMore className='icon-more' />
          </Fragment>
        }
        size='large'
        variant='secondary'
        onClick={handleOpen}
        disabled={!menuItems.length}
      />
      <DownloadMenu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={menuItems}
      />
    </Fragment>
  )
}

export default DropdownButton
