import NumberFormat from 'react-number-format'

export const TELEPHONE_NUMBER_FORMAT = '(###) ###-####'

export const MOBILE_NUMBER_INPUT_PROPS = {
  inputComponent: NumberFormat,
  inputProps: {
    format: TELEPHONE_NUMBER_FORMAT,
    'data-sqa-id': 'mobile'
  }
}

export const EMAIL_DESCRIPTION = 'Order updates will be sent to this email address'
export const EMAIL_EMPTY_ERROR_MESSAGE =
  'Order updates will be sent to this email address. Please verify email to continue. '
export const COMPANY_INFO_TITLE = 'Company Information'
export const COMPANY_INFO_TOOLTIP =
  'We require a company account to order a Collateral Desktop Analysis (CDA).'
export const CONFIRM_TEXT_CONTINUE = 'Continue ->'

export const USER_INFO_FIELDS = [
  {
    id: 'firstName',
    type: 'text',
    label: 'First Name'
  },
  {
    id: 'lastName',
    type: 'text',
    label: 'Last Name'
  },
  {
    id: 'emailAddress',
    type: 'text',
    label: 'Email',
    disabled: true,
    subtitle: 'Order updates will be sent to this email address',
    className: 'email-address'
  },
  {
    id: 'phone',
    type: 'text',
    label: 'Mobile Number - Optional',
    className: 'mobile-number',
    InputProps: MOBILE_NUMBER_INPUT_PROPS,
    subtitle: 'Alternate contact method'
  }
]

export const SIGN_UP_FIELDS = [
  {
    id: 'firstName',
    type: 'text',
    label: 'First Name',
    style: {width: '48%', marginRight: '18px'}
  },
  {
    id: 'lastName',
    type: 'text',
    label: 'Last Name',
    style: {width: '48%'}
  },
  {
    id: 'email',
    type: 'text',
    label: 'Email Address'
  },
  {
    id: 'agreementField'
  }
]

export const COMPANY_INFO_FIELDS = [
  {
    id: 'companyName',
    type: 'text',
    label: 'Company Name'
  },
  {
    id: 'contactEmail',
    type: 'text',
    label: 'Billing Contact Email Address'
  },
  {
    id: 'contactFirstName',
    type: 'text',
    label: 'Billing Contact First Name'
  },
  {
    id: 'contactLastName',
    type: 'text',
    label: 'Billing Contact Last Name'
  }
] as const
