import {AddressType, PropertyType} from 'types/propertyTypes'
import type {
  ProductGroupId,
  ProductGroup,
  ProductGroups,
  ProductId,
  Product,
  Products,
  ProductsResponse,
  ProductOptions,
  ProductGroupType,
  ProductType,
  FulfilledOrdersByPidByProductGroupDictionary
} from 'types/productTypes'
import {opsApi} from 'services/apis'
import {productGroupDictionary} from 'constants/productCardConstants'
import {OpsOrderType} from 'types/orderTypes'

export const getProductGroupDataByProductGroup = (
  productGroup: ProductGroupId,
  productGroups: ProductGroups
) => productGroups.find(product => product.productGroup === productGroup)

export const getProductsByProductGroup = (
  productGroup: ProductGroupId,
  productGroups: ProductGroups
) => getProductGroupDataByProductGroup(productGroup, productGroups)?.products

export const getProductByProductId = (productId: ProductId, products: Products) =>
  products.find(p => p.product === productId)

export const getProduct = ({
  productId,
  productGroups,
  productGroupId
}: {
  productId: ProductId
  productGroups: ProductGroups
  productGroupId?: ProductGroupId
}): Product | undefined => {
  let product: Product | undefined
  let products: Products = []

  if (productGroupId) {
    // if we know which group the product is in then passing it to the function
    // allows us to get the product more efficiently
    products = getProductsByProductGroup(productGroupId, productGroups) ?? []
    product = getProductByProductId(productId, products)
  } else {
    // if we don't know which product group the product is in then we have to
    // search them all
    for (let i = 0; i < productGroups.length; i++) {
      product = productGroups[i].products.find(p => p.product === productId)
      if (product) {
        break
      }
    }
  }

  return product
}

export const getProductsUrl = (propertyAddress: AddressType) => {
  const address = {
    street: propertyAddress.displayAddressLine1,
    city: propertyAddress.city,
    state: propertyAddress.state,
    zipcode: propertyAddress.zip
  }
  const searchParams = new URLSearchParams()
  Object.keys(address)
    .filter(key => !!address[key as keyof typeof address])
    .forEach(key => {
      const value = address[key as keyof typeof address]
      if (value) {
        searchParams.append(key, value)
      }
    })

  const pathname = `products`
  const queryString = searchParams.toString()
  return queryString ? `${pathname}?${queryString}` : pathname
}

export const isSupportedProductGroup = (productGroup: ProductGroup) =>
  !!productGroup.products.find(product => product.isSupported)

export const getProductGroups = async (address?: AddressType) => {
  const url = getProductsUrl(address ?? {})
  return opsApi.get<ProductsResponse>(url)
}

export const getProductOptions = (
  productGroup: ProductGroupId,
  productGroups: ProductGroups
): ProductOptions => {
  const productOptions: ProductOptions = []
  const products = getProductsByProductGroup(productGroup, productGroups)
  products?.forEach(product => {
    const {description: label, product: value, isSupported} = product
    if (isSupported) {
      productOptions.push({label, value})
    }
  })

  return productOptions
}

export const calculateProductTotalPrice = (price: number, creditCardFee: number): string => {
  return Number(creditCardFee ? price + creditCardFee : price).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

export const isAvailable = (productType: ProductGroupType, {pid}: Pick<PropertyType, 'pid'>) => {
  return !productGroupDictionary[productType].needPID || !!pid
}

export const getProductGroup = (
  productType: ProductType,
  productGroups?: ProductsResponse['productGroups']
): ProductGroupType => {
  return productGroups?.find(productGroup =>
    productGroup.products.find(product => product.product === productType)
  )?.productGroup as ProductGroupType
}

export const getOrdersByAddressByProductGroupDictionary = (
  fulfilledOrdersByProductType: {[key in ProductType]: OpsOrderType[]},
  productGroups?: ProductsResponse['productGroups']
): FulfilledOrdersByPidByProductGroupDictionary => {
  return Object.keys(fulfilledOrdersByProductType).reduce<
    FulfilledOrdersByPidByProductGroupDictionary
  >((accumulator, current) => {
    const productGroup = getProductGroup(current as ProductType, productGroups)
    const currentFulfilledOrdersByProductType = fulfilledOrdersByProductType[current as ProductType]

    if (!accumulator[productGroup]) {
      accumulator[productGroup] = currentFulfilledOrdersByProductType
    } else {
      accumulator[productGroup] = [
        ...accumulator[productGroup],
        ...currentFulfilledOrdersByProductType
      ]
    }
    return accumulator
  }, {} as FulfilledOrdersByPidByProductGroupDictionary)
}
