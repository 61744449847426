// Styles
import styled from 'styled-components/macro'

// Core
import React, {Fragment, useState} from 'react'
import {useLocation} from 'react-router-dom'

// Components, services, etc
import Button from 'components/Button'
import Icon from 'components/Icon'
import LinkButton from 'components/LinkButton'
import NavLinkWithRef from 'components/NavLinkWithRef'
import {convertFromJSDateToFormattedDate} from 'services/datetime'
import {handleMixpanelLaunchedProductEvent} from 'services/mixpanel'
import type {OpsOrderType} from 'types/orderTypes'

// 3rd-party
import Menu, {MenuProps} from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import type {NavLinkWithRefProps} from 'components/NavLinkWithRef/NavLinkWithRef'
import {getProductNavPath} from 'services/productNavigationServices'

type ProductOrdersProps = {
  orders?: Array<OpsOrderType>
}

type ProductOrdersButtonProps = {
  order: OpsOrderType
  location: {
    pathname: string
  }
}

type ProductOrdersDropdownProps = {
  anchorEl: MenuProps['anchorEl']
  setAnchorEl: (a?: MenuProps['anchorEl'] | null) => void
  orders: Array<OpsOrderType>
  location: {
    pathname: string
  }
}

const handleOrderClick = (order: OpsOrderType, location: ProductOrdersButtonProps['location']) => {
  handleMixpanelLaunchedProductEvent(location.pathname, order)
}

const ProductOrders = ({orders}: ProductOrdersProps) => {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<MenuProps['anchorEl']>(null)
  return orders && orders.length ? (
    <ProductOrders.Styled>
      {orders.length === 1 ? (
        <ProductOrders.Button order={orders[0]} location={location} />
      ) : (
        <ProductOrders.Dropdown
          orders={orders}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          location={location}
        />
      )}
    </ProductOrders.Styled>
  ) : null
}

ProductOrders.Button = ({order, location}: ProductOrdersButtonProps) => {
  const formattedOrderDate = convertFromJSDateToFormattedDate(order.created, 'MMM DD, YYYY')
  const handleClick = () => {
    handleOrderClick(order, location)
  }
  return (
    <LinkButton
      className='view-order-button'
      variant='secondary'
      title={`View (${formattedOrderDate})`}
      to={getProductNavPath(order)}
      onClick={handleClick}
    />
  )
}

ProductOrders.Dropdown = ({
  orders,
  anchorEl,
  setAnchorEl,
  location
}: ProductOrdersDropdownProps) => {
  const handleOpen: React.MouseEventHandler<HTMLButtonElement> = se => {
    setAnchorEl(se.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (order: OpsOrderType) => () => {
    handleOrderClick(order, location)
  }
  return (
    <Fragment>
      <Button
        className='view-order-button'
        variant='secondary'
        title={
          <span className='dropdown-button-label'>
            View All Orders <Icon icon='expand_more' />
          </span>
        }
        onClick={handleOpen}
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
      >
        {orders.map(order => {
          const {created, opsOrderId} = order
          const formattedOrderDate = convertFromJSDateToFormattedDate(created, 'MMM DD, YYYY')
          return (
            <MenuItem
              key={opsOrderId}
              to={getProductNavPath(order)}
              onClick={handleClick(order)}
              component={NavLinkWithRef as React.ElementType<NavLinkWithRefProps>}
            >
              <span>{formattedOrderDate}</span>
            </MenuItem>
          )
        })}
      </Menu>
    </Fragment>
  )
}

ProductOrders.Styled = styled.div`
  width: 180px;
  .view-order-button {
    margin-left: 8px;
    display: flex;
    align-items: center;

    .dropdown-button-label {
      display: flex;
      align-items: center;
    }

    .material-icons {
      line-height: 22px;
    }
  }
`

export default ProductOrders
