import {
  CustomerUser,
  CustomerUserStatus,
  CustomerType,
  CustomerUserUpdateResponse,
  InviteCustomerRequestBody,
  CustomerUserTable,
  CustomerUsersTableFilters
} from 'types/customerTypes'
import {customerApi} from 'services/apis'
import {CUSTOMER_USERS_STATUS} from 'constants/customerUsersConstants'

export const getCustomerUserStatus = (
  customerUser: CustomerUser
): CustomerUserStatus | undefined => {
  let status: CustomerUserStatus | undefined
  if (!customerUser.isActive) {
    status = 'disabled'
  } else if (customerUser.userStatus === 'FORCE_CHANGE_PASSWORD') {
    status = 'pending'
  } else if (customerUser.userStatus !== 'FORCE_CHANGE_PASSWORD') {
    status = 'enabled'
  }

  return status
}

export const getStatusOptionsByCustomerUserStatus = (customerUserStatus?: CustomerUserStatus) => {
  let statusOptions = CUSTOMER_USERS_STATUS.filter(statusOption => statusOption.value !== 'pending')
  if (customerUserStatus === 'pending') {
    statusOptions = statusOptions.filter(statusOption => statusOption.value !== 'enabled')
  }
  return statusOptions
}

export const getCustomerUserRoles = (roles: string) =>
  roles === 'userManager' ? ['user-management'] : []

export const updateCustomerUser = async (
  customerKey: CustomerType['customerKey'],
  username: CustomerUser['username'],
  roles: string,
  userStatus: CustomerUserStatus
): Promise<CustomerUserUpdateResponse> => {
  const payload = {
    isActive: userStatus === 'enabled',
    roles: getCustomerUserRoles(roles)
  }
  return customerApi.put<CustomerUserUpdateResponse>(
    `customer/${customerKey}/users/${username}`,
    payload
  )
}

export const resendInvite = async (
  customerKey: CustomerType['customerKey'],
  username: CustomerUser['username'],
  email: CustomerUser['email']
): Promise<InviteCustomerRequestBody> => {
  const payload = {
    username,
    email
  }
  return customerApi.post(`customer/${customerKey}/users?resendPassword=true`, payload)
}

export const filterCustomerUsers = (
  customerUsers: CustomerUserTable[],
  filters: CustomerUsersTableFilters
): CustomerUserTable[] => {
  const filteredCustomerUsers = customerUsers.filter(customerUser => {
    let filteredCustomerUser
    const status: CustomerUserStatus[] = []
    if (filters.enabled) {
      status.push('enabled')
    }
    if (filters.pending) {
      status.push('pending')
    }
    if (filters.disabled) {
      status.push('disabled')
    }

    const searchText = `${customerUser?.firstName ?? ''}${customerUser?.lastName ?? ''}${
      customerUser?.email ?? ''
    }`.toLowerCase()

    if (
      customerUser.userStatus &&
      status.includes(customerUser.userStatus) &&
      searchText.includes(filters.search.toLowerCase())
    ) {
      filteredCustomerUser = customerUser
    }
    return filteredCustomerUser
  })

  return filteredCustomerUsers
}
