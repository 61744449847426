// Core
import React from 'react'

// Components, services, constants
import Card from 'components/Card'
import FlatList from 'components/FlatList'
import LabelValuePair from 'components/LabelValuePair'
import type {OpsOrderType} from 'types/orderTypes'

const OrderDetailsCard = ({order}: {order: OpsOrderType}) => {
  const orderDetailsData = [
    {key: 'clientTrackingId', label: 'Tracking ID'},
    {key: 'customerNotes', label: 'Order Instructions'}
  ] as const

  return (
    <Card>
      <h4>Order Details</h4>
      <FlatList
        items={orderDetailsData.map(({key, label}) => {
          return {key, content: <LabelValuePair label={label} value={order[key]} />}
        })}
        withDividers
      />
    </Card>
  )
}

export default OrderDetailsCard
