import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Field, useFormState} from 'react-final-form'

import Select from 'components/Select'
import IconButton from 'components/IconButton'
import {validateRequiredDocumentType} from 'services/formValidatorServices'
import type {OrderFormDocument} from 'types/productTypes'
import {getDocumentTypeOptions, uploadDocument} from 'services/documentsServices'
import {useFieldArray} from 'react-final-form-arrays'
import {DocumentUploadType} from 'types/orderTypes'
import {CircularProgress} from '@material-ui/core'
import {openNotification} from 'services/notificationServices'
import {useDispatch, useSelector} from 'react-redux'
import {DELETE_UPLOADED_DOCUMENTS, SET_UPLOADED_DOCUMENTS} from 'constants/actionTypes'
import {documentsUploadedSelector} from 'selectors/documentsSelectors'

interface AssignDocumentTypeProps {
  fieldName: string
  document: OrderFormDocument['file']
  documentType: DocumentUploadType
  index: number
}

const AssignDocumentType = ({
  fieldName,
  document,
  documentType,
  index
}: AssignDocumentTypeProps) => {
  const {fields} = useFieldArray('customerDocumentList')
  const {errors} = useFormState()
  const options = [...getDocumentTypeOptions()]
  const dispatch = useDispatch()
  const uploadedDocuments = useSelector(documentsUploadedSelector)
  const isLoading = uploadedDocuments[index]?.loading

  useEffect(() => {
    const fetchData = async () => {
      let response
      try {
        dispatch({
          type: SET_UPLOADED_DOCUMENTS,
          payload: {
            name: document.name,
            loading: true
          }
        })

        if (!errors?.customerDocumentList?.[index]?.documentType) {
          response = await uploadDocument(document, documentType, '')
        }

        dispatch({
          type: SET_UPLOADED_DOCUMENTS,
          payload: {
            name: document.name,
            storageKey: response?.storageKey,
            loading: false
          }
        })
      } catch (err) {
        openNotification({
          type: 'error',
          text: err as string
        })
        fields.remove(index)
      }
    }
    documentType && fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentType])

  useEffect(() => {
    const options = getDocumentTypeOptions()
    if (getDocumentTypeOptions().length === 1) {
      fields.update(index, {
        ...fields.value[index],
        documentType: options[0].value
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDeleteFileClickHandler = (event: React.MouseEvent) => {
    event.preventDefault()
    fields.remove(index)
    dispatch({
      type: DELETE_UPLOADED_DOCUMENTS,
      payload: document.name
    })
  }

  return (
    <AssignDocumentType.Styled>
      <div className='col-left'>
        <p className='label'>Document Type</p>
        <Field<OrderFormDocument['documentType']>
          name={`${fieldName}.documentType`}
          validate={value => validateRequiredDocumentType(value, document.name, document.size)}
        >
          {({input, meta}) => {
            return (
              <Select
                className='document-type'
                disabled={options.length === 1}
                error={meta.error && meta.submitFailed}
                helperText={meta.submitFailed && meta.error}
                onChange={ev => input.onChange(ev)}
                options={options}
                value={input.value ?? ''}
              />
            )
          }}
        </Field>
      </div>
      <div className='col-center'>
        <p className='label'>Filename</p>
        <div className='filename-wrapper'>
          <div className='filename'>{document?.name}</div>
          {isLoading && <CircularProgress />}
        </div>
      </div>
      <div className='col-right'>
        <IconButton
          className='delete-button'
          disabled={isLoading}
          icon='delete'
          onClick={event => onDeleteFileClickHandler(event)}
          size='small'
        />
      </div>
    </AssignDocumentType.Styled>
  )
}

AssignDocumentType.Styled = styled.div`
  width: 100%;
  display: flex;

  .col-left {
    width: 200px;
    flex: 0 0 auto;

    .document-type {
      .portal-MuiOutlinedInput-root.Mui-disabled {
        border: 1px solid ${({theme}) => theme.colors.stone.light};
      }
    }
  }

  .col-center {
    flex: 1 1 auto;
    padding-left: 18px;

    .filename-wrapper {
      display: flex;
      padding-top: 10px;
      .filename {
        font-size: 0.75rem;
        line-height: 1.375rem;
        margin-right: 14px;
      }
      .portal-MuiCircularProgress-root {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }

  .col-right {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    .delete-button {
      width: 24px;
      height: 24px;
    }
  }

  .label {
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.25rem;
    margin: 0 0 4px;
  }

  /* override the padding set in our MUI theme overrides for all inputs in this form */
  .portal-MuiFormControl-root {
    padding-bottom: 0;
  }

  .portal-MuiFormControl-root:has(.portal-MuiFormHelperText-root) {
    padding-bottom: 27px;
  }

  .portal-MuiFormHelperText-root {
    overflow: visible;
  }
`

export default AssignDocumentType
