// Styles
import styled from 'styled-components/macro'

// Core
import React, {ChangeEventHandler, Fragment, useEffect, useRef} from 'react'
import {useSelector} from 'react-redux'

// Components, services, etc
import {openModal} from 'actions/modalActions'
import {updateUser} from 'actions/userActions'
import Button from 'components/Button'
import Modal from 'components/Modal'
import TextInput from 'components/TextInput'
import {UNSAVED_WORK_MODAL} from 'constants/modalsConstants'
import {
  COMPANY_INFO_FIELDS,
  COMPANY_INFO_TITLE,
  COMPANY_INFO_TOOLTIP,
  EMAIL_EMPTY_ERROR_MESSAGE,
  USER_INFO_FIELDS
} from 'constants/userProfileConstants'
import {
  companyNameValidationRules,
  emailValidationRules,
  phoneValidationRules,
  requiredValidationRules
} from 'constants/validationRulesConstants'
import {customerNameSelector, customerSelector} from 'selectors/customerSelectors'
import {formattedUserSelector} from 'selectors/userSelectors'
import {RuleType, validateForm} from 'services/formValidationHelpers'

// 3rd-party
import {createCustomer, updateCustomer} from 'actions/customerActions'
import {COMPANY_NAME_TAKEN_ERROR_MESSAGE} from 'constants/confirmCodeConstants'
import {isMatch, kebabCase} from 'lodash'
import {Field, FieldInputProps, Form, FormProps} from 'react-final-form'
import {useAppDispatch} from 'services/store'
import Tooltip from 'components/Tooltip'
import {titleCase} from 'services/formats'
import FetchApiError from 'services/FetchApiError'
import {MOBILE_NUMBER_DUPLICATE_ERROR} from 'constants/validationErrorMessagesConstants'
import {ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP} from 'constants/orderFormConstants'
import {ProductGroupType} from 'types/productTypes'
import {preload} from 'swr'
import {getProductGroups} from 'services/productsServices'
import {refreshTokens} from 'actions/authActions'
import {openNotification} from 'services/notificationServices'
import {UPDATE_USER_PROFILE_MESSAGE} from 'constants/notificationMessageConstants'

type UserProfileModalProps = {
  open: boolean
  handleClose: () => void
  handleExited: () => void
  title?: string
  subTitle?: string
  onConfirmText?: string
  redirectToProduct?: ProductGroupType
}

type FormValuesType = {
  firstName?: string
  lastName?: string
  emailAddress?: string
  companyName?: string
  contactFirstName?: string
  contactLastName?: string
  contactEmail?: string
  phone?: string
}

export type UserProfileModalFormContentProps = {
  handleSubmit: () => void
  userData: {loginType?: string}
  values: FormValuesType
  canEditCompanyName: boolean
  emailVerified?: boolean
}

const fieldRulesDictionary: {[key in keyof Required<FormValuesType>]: RuleType[]} = {
  emailAddress: [
    ...emailValidationRules,
    {
      ...requiredValidationRules[0],
      errorMsg: EMAIL_EMPTY_ERROR_MESSAGE
    }
  ],
  firstName: requiredValidationRules,
  lastName: requiredValidationRules,
  companyName: [...requiredValidationRules, ...companyNameValidationRules],
  contactFirstName: requiredValidationRules,
  contactLastName: requiredValidationRules,
  contactEmail: [...emailValidationRules, ...requiredValidationRules],
  phone: phoneValidationRules
}

const UserProfileModal = ({
  open,
  handleClose,
  handleExited,
  title = 'My Profile',
  subTitle = 'Please keep your information updated so that we can better serve you.',
  onConfirmText = 'Save Changes',
  redirectToProduct
}: UserProfileModalProps) => {
  const dispatch = useAppDispatch()
  const userData = useSelector(formattedUserSelector)

  const customerName = useSelector(customerNameSelector)
  const customer = useSelector(customerSelector)
  const existingCompanyNameMap = useRef<{[key: string]: boolean}>({})
  const {firstName, lastName, email, emailVerified, phone} = userData

  const initialFormValues = useRef({
    firstName,
    lastName,
    phone,
    emailAddress: email,
    companyName: customer ? customerName : '',
    contactFirstName: customer?.billingContact?.firstName,
    contactLastName: customer?.billingContact?.lastName,
    contactEmail: customer?.billingContact?.email
  } as FormValuesType)

  useEffect(() => {
    initialFormValues.current = {
      ...initialFormValues.current,
      emailAddress: email ?? initialFormValues.current.emailAddress
    }
  }, [email])

  const closeModal = () => {
    handleClose()
  }

  const onIgnoreUnsavedWork: (
    closeModal: () => void
  ) => () => void = closeUnsavedWorkModal => () => {
    closeUnsavedWorkModal && closeUnsavedWorkModal()
    closeModal()
  }

  const handleCloseModalRequest = (formIsDirty: boolean) => () => {
    if (formIsDirty) {
      dispatch(
        openModal({
          modalType: UNSAVED_WORK_MODAL,
          onConfirm: onIgnoreUnsavedWork
        })
      )
    } else {
      closeModal()
    }
  }

  const handleFormSubmit: FormProps<FormValuesType>['onSubmit'] = async (values, form) => {
    const {dirty} = form.getState()
    if (dirty) {
      const {
        firstName,
        lastName,
        emailAddress,
        phone,
        companyName,
        contactFirstName,
        contactLastName,
        contactEmail
      } = values

      const userPayload = {
        firstName,
        lastName,
        email: emailAddress,
        phone
      }

      const customerPayload = {
        ...customer,
        name: companyName,
        billingContact: {
          ...customer?.billingContact,
          firstName: contactFirstName,
          lastName: contactLastName,
          email: contactEmail
        }
      }

      try {
        if (!isMatch(userData, userPayload)) {
          try {
            await dispatch(updateUser(userPayload))
          } catch (error) {
            if (
              error instanceof FetchApiError &&
              error.response?.status === 400 &&
              error.payload?.code === 'DuplicateRecordException'
            ) {
              return {
                phone: MOBILE_NUMBER_DUPLICATE_ERROR
              }
            } else {
              openNotification({
                type: 'error',
                text: UPDATE_USER_PROFILE_MESSAGE
              })
            }
          }
        }

        if (!customer) {
          await dispatch(createCustomer(customerPayload))
          await dispatch(refreshTokens())
        } else if (
          customer.customerKey &&
          (!customer.billingContact ||
            !isMatch(customer.billingContact, customerPayload.billingContact))
        ) {
          try {
            await dispatch(updateCustomer(customer.customerKey, customerPayload))
          } catch (error) {
            console.error(error)
          }
        }

        closeModal()

        if (redirectToProduct) {
          const orderFormConfig = ORDER_FORM_CONFIG_MAP_BY_PRODUCT_GROUP[redirectToProduct]
          preload('/products', getProductGroups)
          dispatch(openModal({modalType: 'ORDER_FORM_MODAL', orderFormConfig}))
        }
      } catch (err) {
        if ((err as {response: {status: number}}).response.status === 409 && companyName) {
          existingCompanyNameMap.current[companyName] = true
          const {
            mutators: {setFieldTouched}
          } = form
          // we need to manually trigger the field as touched so the error
          // will be shown
          setFieldTouched('companyName', true)
        }

        console.error('update user error', err)
      }
    } else {
      closeModal()
    }
  }

  const setFieldTouched = (args: [string, boolean], state: {fields: {[x: string]: any}}) => {
    const [name, touched] = args
    const field = state.fields[name]
    if (field) {
      field.touched = !!touched
    }
  }

  const validate = (values: FormValuesType) => {
    fieldRulesDictionary.companyName = [
      ...fieldRulesDictionary.companyName,
      {
        rule: (companyName: string) => !existingCompanyNameMap.current[companyName],
        errorMsg: COMPANY_NAME_TAKEN_ERROR_MESSAGE
      }
    ]
    return validateForm(fieldRulesDictionary, values)
  }

  return (
    <Form
      initialValues={initialFormValues.current}
      onSubmit={handleFormSubmit}
      validate={validate}
      mutators={{setFieldTouched}}
      render={({
        handleSubmit,
        dirty,
        dirtySinceLastSubmit,
        submitting,
        values,
        submitFailed,
        hasValidationErrors,
        hasSubmitErrors
      }) => (
        <Fragment>
          <UserProfileModal.Styled
            size='md'
            open={open}
            onClose={handleCloseModalRequest(dirty)}
            onExited={handleExited}
            showCloseButton
          >
            <Modal.Header>
              <div className='modal-title-wrap'>
                <h3>{title}</h3>
                <span className='subtitle'>{subTitle}</span>
              </div>
            </Modal.Header>
            <Modal.Content>
              <UserProfileModal.FormContent
                handleSubmit={handleSubmit}
                userData={userData}
                values={values}
                canEditCompanyName={!customer}
                emailVerified={emailVerified}
              />
            </Modal.Content>
            <Modal.Footer>
              <Button
                variant='secondary'
                title='Cancel'
                size='large'
                onClick={handleCloseModalRequest(dirty)}
              />
              <Button
                className='btn-submit'
                variant='primary'
                disabled={
                  submitting ||
                  (submitFailed && hasValidationErrors) ||
                  (hasSubmitErrors && !dirtySinceLastSubmit)
                }
                isLoading={submitting}
                title={submitting ? <span>Saving Changes&hellip;</span> : onConfirmText}
                type='submit'
                size='large'
                onClick={handleSubmit}
              />
            </Modal.Footer>
          </UserProfileModal.Styled>
        </Fragment>
      )}
    />
  )
}

UserProfileModal.FormContent = ({
  handleSubmit,
  userData = {},
  canEditCompanyName
}: UserProfileModalFormContentProps) => {
  const {loginType} = userData
  const isFederatedLogin = loginType && loginType !== 'CAS'

  const handleInputChange: (
    input: FieldInputProps<string, HTMLInputElement>
  ) => ChangeEventHandler<HTMLInputElement> = input => e => input.onChange(e.target.value)

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <h4>Personal Information</h4>
        <div className='section-content'>
          {USER_INFO_FIELDS.map(({className, id, label, disabled, subtitle, ...rest}) => {
            return (
              <Field name={id} key={id}>
                {({input, meta}) => {
                  return (
                    <div className={className}>
                      <div>
                        <div className='subtitle-wrapper'>
                          <TextInput
                            label={label}
                            value={input.value}
                            onChange={handleInputChange(input)}
                            error={
                              (meta.submitError && !meta.dirtySinceLastSubmit) ||
                              (meta.submitFailed && meta.error)
                            }
                            helperText={
                              meta.submitError && !meta.dirtySinceLastSubmit
                                ? meta.submitError
                                : meta.submitFailed && meta.error
                            }
                            disabled={disabled}
                            sqaPrefix={kebabCase(id)}
                            {...rest}
                          />
                          {subtitle && <p className='subtitle'>{subtitle}</p>}
                        </div>
                        {id === 'emailAddress' && isFederatedLogin ? (
                          <div className='connected-message'>
                            {`Connected through ${titleCase(loginType)}`}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )
                }}
              </Field>
            )
          })}
        </div>
      </section>
      <hr className='divider' />
      <section className='company-info-section'>
        <Tooltip className='company-info-tooltip' text={COMPANY_INFO_TOOLTIP} placement='right-end'>
          <h4 className='has-subtitle'>{COMPANY_INFO_TITLE}</h4>
        </Tooltip>
        <div className='section-content'>
          {COMPANY_INFO_FIELDS.map(({id, label}) => {
            return (
              <Field name={id} key={id}>
                {({input, meta}) => {
                  return (
                    <TextInput
                      label={label}
                      value={input.value}
                      onChange={handleInputChange(input)}
                      error={meta.error && meta.touched}
                      helperText={meta.touched && meta.error}
                      disabled={id === 'companyName' && !canEditCompanyName}
                    />
                  )
                }}
              </Field>
            )
          })}
        </div>
      </section>
    </form>
  )
}

UserProfileModal.Styled = styled(Modal)`
  .header {
    height: 80px;
    padding-bottom: 0;

    .modal-title-wrap {
      align-self: flex-start;

      h3 {
        margin-bottom: 8px;
      }
      .subtitle {
        display: block;
        font-size: 0.875rem;
        line-height: 1.375rem;
        color: ${({theme}) => theme.colors.grayscale.gray};
      }
    }

    .close-button {
      align-self: flex-start;
      /* account for button padding */
      margin-top: -6px;
    }
  }

  .content {
    &.portal-MuiDialogContent-root {
      padding-top: 22px;

      section {
        .section-content {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          row-gap: 23px;
          column-gap: 16px;

          .email-address {
            grid-column-start: 1;
            grid-column-end: 4;
            > div:first-child {
              display: flex;
              align-items: center;
            }
            .subtitle-wrapper {
              width: 336px;
            }
            .connected-message {
              margin-left: 18px;
              margin-bottom: 14px;
              color: ${({theme}) => theme.colors.grayscale.gray};
            }
          }
        }

        h4 {
          font-size: 0.875rem;
          margin-bottom: 16px;
        }

        .subtitle {
          color: ${({theme}) => theme.colors.grayscale.gray};
          font-size: 0.75rem;
          font-weight: 400;
          margin-top: 5px;
          margin-bottom: -6px;
        }

        &.company-info-section {
          .section-content {
            margin-top: 16px;
          }

          .company-info-tooltip {
            display: inline-block;

            .has-subtitle {
              margin-bottom: 0;
            }
          }
        }

        .portal-MuiFormControl-root {
          padding-bottom: 0;
        }

        .portal-MuiFormHelperText-root {
          position: relative;
          white-space: normal;
        }

        + section {
          margin-top: 32px;
        }
      }
      .divider {
        color: ${({theme}) => theme.colors.grayscale.black};
        opacity: 0.1;
        height: 1px;
        margin-top: 24px;
        margin-bottom: 19px;
      }
    }
  }

  .footer {
    padding: 16px 24px;
    .btn-submit {
      width: 139px;
    }
  }
`

export default UserProfileModal
