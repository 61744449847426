import {
  RESET_ORDERS,
  RESET_ORDERS_BY_OPS_ORDER_ID,
  SET_CURRENT_PAGE,
  SET_ORDERS_BY_ADDRESS_ERROR,
  SET_ORDERS_BY_ADDRESS_LOADED,
  SET_ORDERS_BY_ADDRESS_LOADING,
  RESET_ORDERS_BY_ADDRESS,
  SET_ORDERS_ERROR,
  SET_ORDERS_LOADED,
  SET_ORDERS_PAGING_LOADING,
  SET_ORDER_BY_OPS_ORDER_ID_ERROR,
  SET_ORDER_BY_OPS_ORDER_ID_LOADED,
  SET_ORDER_BY_OPS_ORDER_ID_LOADING,
  SET_ORDER_PAGINATION_HISTORY,
  SET_PER_PAGE,
  UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID
} from 'constants/actionTypes'
import {customerKeySelector} from 'selectors/customerSelectors'
import {
  lastEvaluatedSelector,
  ordersPerPageSelector,
  pageHistorySelector
} from 'selectors/ordersSelectors'
import {opsApi} from 'services/apis'
import {createGetUrlWithCustomerKey, getOrdersForAddressUrl} from 'services/ordersServices'

// 3rd-party
import {TablePaginationDirection} from 'components/TablePagination/TablePagination'
import type {RootAction} from 'reducers'
import FetchApiError from 'services/FetchApiError'
import {AppDispatch, RootState} from 'services/store'
import {createQueryStringFromObject} from 'services/urlServices'
import type {
  GetOrderListResponse,
  OpsOrderType,
  OrdersForAddressArguments,
  OrderStatus
} from 'types/orderTypes'

export function setInitialOrders(res: GetOrderListResponse, error: any) {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_ORDERS_LOADED,
      payload: res
    })
    dispatch({
      type: SET_ORDER_PAGINATION_HISTORY,
      payload: {
        page: 1
      }
    })
    if (error) {
      dispatch({
        type: SET_ORDERS_ERROR,
        payload: error
      })
    }
  }
}

export function getOrdersPaginated(direction?: TablePaginationDirection, currentPage = 1) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const ordersPerPage = ordersPerPageSelector(state)
    const customerKey = customerKeySelector(state)
    const {created, opsOrderId} =
      (direction === 'forward' ? lastEvaluatedSelector(state) : pageHistorySelector(state)) || {}
    let getUrl = `orders?index=recent&scanReverse=false&count=${ordersPerPage}`
    if (created && opsOrderId) {
      getUrl = `${getUrl}&${createQueryStringFromObject({
        lastOpsOrderId: opsOrderId,
        lastCreated: created
      })}`
    }

    getUrl = createGetUrlWithCustomerKey(getUrl, customerKey)

    dispatch({
      type: SET_ORDERS_PAGING_LOADING
    })

    return opsApi
      .get<GetOrderListResponse>(getUrl)
      .then(res => {
        dispatch({
          type: SET_ORDERS_LOADED,
          payload: res
        })
        dispatch({
          type: SET_ORDER_PAGINATION_HISTORY,
          payload: {
            page: currentPage,
            created,
            opsOrderId
          }
        })
      })
      .catch(err => {
        dispatch({
          type: SET_ORDERS_ERROR,
          payload: err
        })
      })
  }
}

export function setCurrentPage(pageNumber: number): RootAction {
  return {
    type: SET_CURRENT_PAGE,
    payload: pageNumber
  }
}

export function getOrdersForAddress(orderArgs: OrdersForAddressArguments) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const customerKey = customerKeySelector(state)

    dispatch({
      type: SET_ORDERS_BY_ADDRESS_LOADING
    })

    const getUrl = getOrdersForAddressUrl(orderArgs, customerKey)

    return opsApi
      .get<GetOrderListResponse>(getUrl)
      .then(res => {
        dispatch({
          type: SET_ORDERS_BY_ADDRESS_LOADED,
          payload: res
        })
      })
      .catch(err => {
        dispatch({
          type: SET_ORDERS_BY_ADDRESS_ERROR,
          payload: err
        })
      })
  }
}

// This is a recursive function used to fetch until we have either all orders or the correct
// number of fulfilled orders. It fetches 10 orders at a time.

export function getOrderByOpsOrderId(opsOrderId?: string | null) {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const customerKey = customerKeySelector(state)

    dispatch({
      type: SET_ORDER_BY_OPS_ORDER_ID_LOADING
    })

    const getUrl = createGetUrlWithCustomerKey(`orders/${opsOrderId}`, customerKey)

    return opsApi
      .get<OpsOrderType>(getUrl)
      .then(res => {
        if (res.opsOrderId) {
          dispatch({
            type: SET_ORDER_BY_OPS_ORDER_ID_LOADED,
            payload: res
          })
        } else {
          throw new FetchApiError(`can not find order ${opsOrderId}`)
        }
      })
      .catch(err => {
        dispatch({
          type: SET_ORDER_BY_OPS_ORDER_ID_ERROR,
          payload: err
        })
      })
  }
}

export function resetOrdersByOpsOrderId(): RootAction {
  return {
    type: RESET_ORDERS_BY_OPS_ORDER_ID
  }
}

export function setOrdersTablePerPage(val: number): RootAction {
  localStorage.setItem('ordersPerPagePreference', String(val))
  return {
    type: SET_PER_PAGE,
    payload: val
  }
}

export function resetOrdersState(): RootAction {
  return {
    // @ts-ignore invalid type, no reducer is listening this
    type: RESET_ORDERS
  }
}

export function resetOrdersByPId(): RootAction {
  return {
    type: RESET_ORDERS_BY_ADDRESS
  }
}

export const updateOrderStatusByOpsOrderId = (orderStatus: OrderStatus) => {
  return {
    type: UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID,
    payload: orderStatus
  }
}
