// Style
import assignedSvg from 'assets/orderStatus/assigned.svg'
import canceledSvg from 'assets/orderStatus/canceled.svg'
import completedSvg from 'assets/orderStatus/completed.svg'
import onHoldSvg from 'assets/orderStatus/onhold.svg'
import orderedSvg from 'assets/orderStatus/ordered.svg'
import reviewingSvg from 'assets/orderStatus/reviewing.svg'
import styled from 'styled-components'

// Core
import React, {Fragment} from 'react'

// Components and services
import {Cancel, PauseCircleFilled, PlayCircleFilled} from '@material-ui/icons'
import Button from 'components/Button'
import Image from 'components/Image'
import {NO_DATA} from 'constants/appConstants'
import type {CDADisplayStatus} from 'services/orderDetailsServices'
import {getDescription, getViewStatus} from 'services/orderDetailsServices'
import {getOrderProgressStepByOrderStatus} from 'services/ordersServices'
import {OpsOrderType} from 'types/orderTypes'
import LoadDetailsCard from './LoadDetails'
import OrderDetailsCard from './OrderDetails'
import StatusProgressBar from './StatusProgressBar'
import {useAppDispatch} from 'services/store'
import {openModal} from 'actions/modalActions'

// Third parties
import moment from 'moment'

const STATUS_INFO_MAP: {
  [key in CDADisplayStatus]: {imageUrl: string; title: string; description?: string}
} = {
  ORDERED: {imageUrl: orderedSvg, title: 'We are processing your order'},
  ACCEPTED: {imageUrl: orderedSvg, title: 'We have received your order'},
  ASSIGNED: {imageUrl: assignedSvg, title: 'Appraiser is working on your report'},
  REVIEWING: {imageUrl: reviewingSvg, title: 'We are reviewing your report'},
  COMPLETED: {imageUrl: completedSvg, title: 'Your report is now available for download'},
  CANCELED: {
    imageUrl: canceledSvg,
    title: 'Your order has been cancelled',
    description: 'Cancellation details have been emailed to you.'
  },
  CANCEL_REQUESTED: {
    imageUrl: canceledSvg,
    title: 'Your cancellation request is being processed',
    description: 'We will email you when we have an update.'
  },
  ON_HOLD: {
    imageUrl: onHoldSvg,
    title: 'Your order has been placed on hold',
    description:
      'If our support team placed the hold, you would have received an email with further instructions.'
  },
  ON_HOLD_REMOVING: {
    imageUrl: reviewingSvg,
    title: 'We are processing your hold removal request',
    description: 'We will email you when we have an update.'
  }
}

type OrderDetailsStatusProps = {
  order: OpsOrderType
}
const OrderDetailsStatus = ({order}: OrderDetailsStatusProps) => {
  const viewStatus = getViewStatus(order)
  const {imageUrl, title, description} = STATUS_INFO_MAP[viewStatus]
  const {status: {orderStatus} = {orderStatus: 'ORDERED'}} = order
  const dispatch = useAppDispatch()
  const isCanceled = orderStatus === 'CANCELED'
  const isCompleted = orderStatus === 'COMPLETED'
  const isOrdered = orderStatus === 'ORDERED'

  const onHold = () => {
    dispatch(
      openModal({
        modalType: 'CHANGE_ORDER_STATUS_MODAL',
        mode: 'ON_HOLD',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onRemoveHold = () => {
    dispatch(
      openModal({
        modalType: 'HOLD_REMOVAL_CONFIRMATION_MODAL',
        opsOrderId: order.opsOrderId
      })
    )
  }

  const onCancelOrder = () => {
    dispatch(
      openModal({
        modalType: 'CHANGE_ORDER_STATUS_MODAL',
        mode: 'CANCELLATION',
        opsOrderId: order.opsOrderId
      })
    )
  }

  return (
    <OrderDetailsStatus.Styled>
      <div className='status-container'>
        <div className='status-header'>
          <div>
            <h3>Order #{order.productOrderId}</h3>
            <p>Placed {moment(order.created).format('MMM DD, YYYY')}</p>
          </div>
          {!isOrdered && !isCompleted && !isCanceled && (
            <div className='button-container'>
              {viewStatus !== 'ON_HOLD' && viewStatus !== 'ON_HOLD_REMOVING' ? (
                <Button
                  title={
                    <Fragment>
                      <PauseCircleFilled />
                      <span>Place on Hold</span>
                    </Fragment>
                  }
                  onClick={onHold}
                  variant='tertiary'
                  disabled={viewStatus === 'CANCEL_REQUESTED'}
                />
              ) : (
                <Button
                  title={
                    <Fragment>
                      <PlayCircleFilled />
                      <span>Request Hold Removal</span>
                    </Fragment>
                  }
                  onClick={onRemoveHold}
                  disabled={viewStatus === 'ON_HOLD_REMOVING'}
                  variant='tertiary'
                />
              )}
              <Button
                title={
                  <Fragment>
                    <Cancel />
                    <span>Request Cancellation</span>
                  </Fragment>
                }
                variant='tertiary'
                onClick={onCancelOrder}
                disabled={viewStatus === 'CANCEL_REQUESTED'}
              />
            </div>
          )}
        </div>
        <div className='status-details'>
          <Image src={imageUrl} width='96px' height='96px' />
          <h3>{title}</h3>
          <p>{description || getDescription(order)}</p>
        </div>
        {!isCanceled && <StatusProgressBar step={getOrderProgressStepByOrderStatus(orderStatus)} />}
        <div className='order-details'>
          <LoadDetailsCard order={order} />
          <OrderDetailsCard order={order} />
        </div>
      </div>
    </OrderDetailsStatus.Styled>
  )
}

OrderDetailsStatus.Styled = styled.div`
  padding: 40px;
  flex: 1;

  .status-container {
    background: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 24px;
  }

  .status-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    h3 {
      line-height: 28px;
      margin-bottom: 4px;
    }

    p {
      margin-bottom: 0;
      margin-top: 0;
      line-height: 17px;
    }

    .portal-MuiSvgIcon-root {
      margin-right: 6px;
      font-size: 0.875rem;
    }

    .button-container {
      .portal-MuiButton-root + .portal-MuiButton-root {
        margin-left: 24px;
      }
    }
  }

  .status-details {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;

    h3 {
      margin-top: 24px;
      margin-bottom: 8px;
      line-height: 22px;
    }

    p {
      margin-top: 0;
      line-height: 22px;
    }
  }

  .order-details {
    display: flex;
    gap: 24px;

    .flat-list {
      flex-direction: column;

      .value:empty:before {
        content: "${NO_DATA}";
      }
    }

    .card {
      flex: 1;
      border: 1px solid ${({theme}) => theme.colors.stone.light};
      box-shadow: none;
      padding-bottom: 8px;

      h4 {
        line-height: 28px;
      }
    }
  }

  @media (max-width: 1024px) {
    .order-details {
      flex-direction: column;
    }
  }
`

export default OrderDetailsStatus
