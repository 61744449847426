import {OrderStatus, OrderDisplayStatus, OrderAlert} from 'types/orderTypes'
import type {ThemeType} from 'styles/styledComponentsTheme'

export const ORDERS_PER_PAGE_OPTIONS = [50, 100, 200]
export const ORDERS_PAGING_LOADING = 'ORDERS_PAGING_LOADING'

export const ORDER_DISPLAY_STATUS_MAP: Record<OrderStatus, OrderDisplayStatus> = {
  ORDERED: 'Order Placed',
  ACCEPTED: 'Order Placed',
  ASSIGNED: 'Vendor Working',
  REVIEWING: 'Under Review',
  COMPLETED: 'Report Delivered',
  CANCELED: 'Canceled'
}

export const ORDER_DISPLAY_ALERTS_MAP: Record<
  OrderAlert,
  {
    text?: string
    color: Exclude<keyof ThemeType['colors'], 'grayscale'>
  }
> = {
  'Contact Support': {
    text: 'Please contact support regarding order #',
    color: 'ruby'
  },
  'Payment Failed': {
    text:
      'Your payment has been declined. Please verify your payment details and try placing the order again.',
    color: 'ruby'
  },
  'Payment Processing': {
    text:
      'Your payment is currently being processed. The order will be placed once the payment is complete.',
    color: 'amethyst'
  },
  'On Hold': {
    text: '',
    color: 'citrine'
  },
  'Cancelation Requested': {
    text: '',
    color: 'amethyst'
  },
  'Revision Requested': {
    text: '',
    color: 'amethyst'
  }
}
