// Styles
import styled from 'styled-components'

// Core
import type {PropsWithChildren, ReactNode} from 'react'
import React from 'react'

type FormFieldProps = PropsWithChildren<{label: string; action?: ReactNode; className?: string}>
const FormField = ({label, action, children, className}: FormFieldProps) => {
  return (
    <FormField.Styled className={className}>
      <div className='form-field-header'>
        <FormFieldLabel>{label}</FormFieldLabel>
        {action}
      </div>
      {children}
    </FormField.Styled>
  )
}

FormField.Styled = styled.div`
  background: ${({theme}) => theme.colors.stone.lighter};
  border-radius: 6px;
  padding: 24px;

  .form-field-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const FormFieldLabel = ({children}: PropsWithChildren<{}>) => {
  return <FormFieldLabel.Styled>{children}</FormFieldLabel.Styled>
}

FormFieldLabel.Styled = styled.label`
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
`

export default FormField
