import {
  DOWNLOADED_PDF,
  LANDING_PAGE,
  LAUNCHED_PRODUCT,
  MIXPANEL_TOKEN,
  OPENED_MODAL,
  ORDERS_LIST_PAGE,
  PROPERTY_OVERVIEW_PAGE,
  SKIP_EVENT,
  VISITED_LANDING_PAGE,
  FEDERATED_LOGIN_PAGE
} from 'constants/mixpanelConstants'
import {
  COOKIE_MODAL,
  LOG_IN_SIGN_UP_MODAL,
  UNSAVED_WORK_MODAL,
  UPDATE_VERIFICATION_MODAL
} from 'constants/modalsConstants'
import mixpanel, {Dict} from 'mixpanel-browser'
import {walletSelector} from 'selectors'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {customerKeySelector, customerNameSelector} from 'selectors/customerSelectors'
import {userSelector} from 'selectors/userSelectors'
import {getAnalyticsCookieValue} from 'services/cookieServices'
import type {RootState} from 'services/store'
import store from 'services/store'
import type {OpsOrderType} from 'types/orderTypes'
import {titleCase} from './formats'
import {getEnv} from 'services/apis'

export const isMixpanelUserIdentified = () => userIdentified

// this will initialize Mixpanel
export const initializeMixpanel = () => {
  const analyticsCookie = getAnalyticsCookieValue()
  mixpanel.init(MIXPANEL_TOKEN, {
    disable_persistence: true,
    // Set the initial value of opt_out_tracking_by_default based on the saved
    // analytics cookie value
    opt_out_tracking_by_default: !analyticsCookie
  })

  // disable mixpanel in NPE to avoid extra costs cause by huge number
  // of MTUs (Monthly Tracked Users) generated by the daily automation testing.
  // disabling this way avoids the need to add checks in all the mixpanel functions.
  if (getEnv() !== 'production') {
    mixpanel.disable()
  }
}

// we need to run this only after a user logs in
export const identifyMixpanelUser = () => {
  const state = store.getState()
  const user = userSelector(state) ?? {}
  const {id, username, createdDate} = user

  mixpanel.identify(id)
  mixpanel.people.set_once({username, userId: id, signUpDate: createdDate})
  setMixpanelUserData()
  setUserIdentified(true)
}

export const getMixpanelUserData = () => {
  const state = store.getState()
  const user = userSelector(state) ?? {}
  const {firstName, lastName, email} = user

  const customerKey = customerKeySelector(state)
  const customerName = customerNameSelector(state)

  return {
    companyId: customerKey,
    companyName: customerKey ? customerName : '',
    $first_name: firstName,
    $last_name: lastName,
    $email: email
  }
}

export const setMixpanelUserData = () => {
  const mixpanelUserData = getMixpanelUserData()

  mixpanel.people.set(mixpanelUserData)
}

export const resetMixpanelData = () => {
  mixpanel.reset()
  setUserIdentified(false)
}

// this will add the super properties so they are tracked with all events
export const setMixpanelSuperProperties = () => {
  const state = store.getState()

  mixpanel.register(createSuperPropertiesPayload(state))
}

export type MixpanelEventData = Dict

export const trackMixpanelEvent = (eventType: string, eventData?: MixpanelEventData) => {
  const payload = createMixpanelTrackPayload(eventType, eventData)

  // This allows us to return SKIP_EVENT for a payload and avoid tracking an event
  if (payload !== SKIP_EVENT) {
    mixpanel.track(eventType, payload)
  }
}

export const getMixpanelPage = (pathname: string) => {
  let page
  if (pathname === '/products') {
    page = LANDING_PAGE
  } else if (pathname === '/property-overview') {
    page = PROPERTY_OVERVIEW_PAGE
  } else if (pathname === '/orders') {
    page = ORDERS_LIST_PAGE
  } else if (pathname === '/social-auth-tokens') {
    page = FEDERATED_LOGIN_PAGE
  }

  return page
}

export const handleMixpanelLaunchedProductEvent = (pathname: string, order: OpsOrderType) => {
  trackMixpanelEvent(LAUNCHED_PRODUCT, {pathname, order})
}

export const handleMixpanelSuperPropertiesAndUser = () => {
  setMixpanelSuperProperties()
  const state = store.getState()
  const isLoggedIn = isUserAuthenticatedSelector(state)
  if (isLoggedIn) {
    identifyMixpanelUser()
  }
}

const createMixpanelTrackPayload = (eventType: string, eventData?: MixpanelEventData) => {
  const state = store.getState()
  let payload: MixpanelEventData | typeof SKIP_EVENT | undefined
  switch (eventType) {
    case VISITED_LANDING_PAGE:
      payload = createVisitedLandingPagePayload(state)
      break

    case LAUNCHED_PRODUCT:
      payload = createLaunchedProductPayload(eventData!)
      break

    case OPENED_MODAL:
      payload = createOpenedModalPayload(state, eventData!)
      break

    case DOWNLOADED_PDF:
      payload = createDownloadedPdfPayload(eventData!)
      break

    default:
      payload = eventData
      break
  }

  return payload
}

const createVisitedLandingPagePayload = (state: RootState) => {
  const isUserAuthenticated = isUserAuthenticatedSelector(state)
  return {
    isUserAuthenticated
  }
}

const createLaunchedProductPayload = (eventData: MixpanelEventData) => {
  const {pathname, order} = eventData
  const page = getMixpanelPage(pathname)
  const {opsOrderId, productType = ''} = order
  return {
    page,
    product: productType.toUpperCase(),
    orderId: opsOrderId
  }
}

const createOpenedModalPayload = (state: RootState, eventData: MixpanelEventData) => {
  const {modalType, mixpanelEventData = {}} = eventData
  let mixpanelModalType = titleCase(modalType)
  const initiatedFrom = mixpanelEventData?.initiatedFrom
  switch (modalType) {
    // We return SKIP_EVENT here to filter these modals out. We will not track the opened modal
    // event for these modals.
    case 'HOLD_REMOVAL_CONFIRMATION_MODAL':
    case UPDATE_VERIFICATION_MODAL:
    case UNSAVED_WORK_MODAL:
    case COOKIE_MODAL:
      return SKIP_EVENT

    case LOG_IN_SIGN_UP_MODAL:
      {
        const {mode} = eventData
        mixpanelModalType = mode === 'signUp' ? 'Sign Up Modal' : 'Log In Modal'
      }
      break

    default:
      // Do nothing
      break
  }

  return {
    modalType: mixpanelModalType,
    initiatedFrom
  }
}

const createSuperPropertiesPayload = (state: RootState) => {
  const isUserAuthenticated = isUserAuthenticatedSelector(state)
  const {id} = userSelector(state)
  const {stripeCustomerExists} = walletSelector(state)
  const customerKey = customerKeySelector(state)

  return {
    isLoggedIn: isUserAuthenticated,
    // If the user has a card on file (stripeCustomerExists) OR is part of an org, consider them a customer
    userType: stripeCustomerExists || !!customerKey ? 'customer' : 'prospect',
    userId: id,
    companyId: customerKey
  }
}

const createDownloadedPdfPayload = (eventData: MixpanelEventData) => {
  const {productType, order} = eventData

  let payload
  if (productType === 'ClearProp') {
    const {created} = order
    payload = {
      orderDate: created
    }
  }

  return payload
}

// userIdentified is used to track whether we've called mixpanel.identify
// since loading the app or since calling mixpanel.reset
// do not export this variable, it's value must be toggled using
// `setUserIdentified` and retrieved using the exported function
// named `isMixpanelUserIdentified`
let userIdentified = false

const setUserIdentified = (boolean: boolean) => {
  userIdentified = boolean
}
