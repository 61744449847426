// Style
import styled from 'styled-components'

// Core
import {Button, ButtonProps, CircularProgress, Tooltip} from '@material-ui/core'
import React, {Fragment, useRef, useState} from 'react'

// Components, others
import {variantMap} from 'components/Button/Button'
import Icon from 'components/Icon'

type UploadButtonProps = {
  variant?: keyof typeof variantMap
  fileName?: string
  onSelectFiles: (file: any) => void
  uploading?: boolean
  isMultiple?: boolean
} & Omit<ButtonProps, 'variant'>

const UploadButton = ({
  variant = 'secondary',
  fileName,
  onSelectFiles,
  uploading,
  isMultiple = false,
  ...buttonProps
}: UploadButtonProps) => {
  const [inputValue, setInputValue] = useState('')
  const randomId = useRef(`upload-button-${new Date().getTime()}`)

  return (
    <Fragment>
      <Input
        id={randomId.current}
        type='file'
        multiple={isMultiple}
        value={inputValue}
        onChange={e => {
          setInputValue('')
          if (e.target.files?.length) {
            onSelectFiles(e.target.files)
          }
        }}
      />
      <ButtonContainer htmlFor={randomId.current}>
        <Button component='span' variant={variantMap[variant]} {...buttonProps}>
          {getFileName(fileName, uploading)}
        </Button>
      </ButtonContainer>
    </Fragment>
  )
}

const getFileName = (name?: string, uploading?: boolean) => {
  let nameFragment
  if (uploading) {
    nameFragment = (
      <Fragment>
        <span>Uploading</span>
        <div className='loading-icon'>
          <CircularProgress />
        </div>
      </Fragment>
    )
  } else if (!name) {
    nameFragment = (
      <Fragment>
        <Icon icon='file_upload' />
        <span>Upload File</span>
      </Fragment>
    )
  } else {
    let displayFileName
    const [fileName, suffix = ''] = name.split(/\.(\w+)$/)
    if (fileName.length > 18) {
      const displayName = `${fileName.substring(0, 18)}...${suffix}`
      displayFileName = (
        <Tooltip title={name}>
          <span>{displayName}</span>
        </Tooltip>
      )
    } else {
      displayFileName = <span>{name}</span>
    }

    nameFragment = (
      <Fragment>
        {displayFileName}
        <Icon icon='close' />
      </Fragment>
    )
  }

  return nameFragment
}

const Input = styled.input`
  display: none;
`

const ButtonContainer = styled.label`
  .portal-MuiButton-label {
    column-gap: 4px;
  }

  .material-icons {
    font-size: 1rem;
  }

  .loading-icon {
    width: 1rem;
    height: 1rem;
    margin-left: 8px;
  }
`

export default UploadButton
