import type {QueryAddressType} from 'actions/propertyActions'
import {CLEAR_AVM_FULLY_SUPPORTED, CLEAR_AVM_VALUES_ONLY} from 'constants/productCardConstants'
import type {OpsOrderType} from 'types/orderTypes'
// creates the link path to a product
export const getProductNavPath = (
  order: Pick<OpsOrderType, 'productType' | 'productOrderId' | 'opsOrderId' | 'suppliedAddress'>
) => {
  const {productType, opsOrderId, productOrderId} = order
  let path = ''

  if (productOrderId) {
    let productTypeInUrl
    if (productType === 'CDA_MLS') {
      productTypeInUrl = 'cda'
    } else if (productType === CLEAR_AVM_FULLY_SUPPORTED || productType === CLEAR_AVM_VALUES_ONLY) {
      productTypeInUrl = 'avm'
    } else {
      productTypeInUrl = productType.toLowerCase()
    }

    path = `/orders/${productTypeInUrl}/${opsOrderId}`
  }

  return path
}

export const getPropertyUrlByAddress = (address: QueryAddressType) => {
  return address ? `/property-overview?${encodeURIComponent(JSON.stringify(address))}` : ''
}
