// Styles
import styled from 'styled-components/macro'

// Core
import React, {ReactNode, useCallback, useMemo} from 'react'
import {useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'

// Components, services, constants
import Address from 'components/Address'
import Image from 'components/Image'
import OrderDownload from 'components/OrderDownload'
import OrderTableFooter from 'components/OrderTableFooter'
import {NO_DATA} from 'constants/appConstants'
import {
  ORDERS_PAGING_LOADING,
  ORDER_DISPLAY_ALERTS_MAP,
  ORDER_DISPLAY_STATUS_MAP
} from 'constants/ordersConstants'
import {ordersStatusSelector, OrdersTableDataItem, ordersTableDataSelector} from 'selectors'
import {customerSelector} from 'selectors/customerSelectors'
import {handleMixpanelLaunchedProductEvent} from 'services/mixpanel'
import {getProductNavPath} from 'services/productNavigationServices'
import {getSqaId} from 'services/testingServices'

// 3rd party
import {TableRowProps} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import MUITable from '@material-ui/core/Table'
import MUITableBody from '@material-ui/core/TableBody'
import MUITableCell from '@material-ui/core/TableCell'
import MUITableContainer from '@material-ui/core/TableContainer'
import MUITableHead from '@material-ui/core/TableHead'
import MUITableRow from '@material-ui/core/TableRow'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import {productGroupDictionary} from 'constants/productCardConstants'
import {OpsOrderType} from 'types/orderTypes'
import {getAlertsByOrder, getOrderAlertTooltipTextByAlertType} from 'services/ordersServices'
import Tooltip from 'components/Tooltip'
import Chip from 'components/Chip'
import useProductGroups from 'hooks/useProductGroups'
import {getProductGroup} from 'services/productsServices'

type Props = {
  className?: string
  sqaPrefix: string
}
type Header = {
  id:
    | 'address'
    | 'productGroup'
    | 'status'
    | 'alerts'
    | 'created'
    | 'orderedBy'
    | 'clientTrackingId'
    | 'pdf'
  label: string
  align: 'center' | 'left' | 'right'
  style?: object
  render: (orderData: OrdersTableDataItem) => ReactNode
}

type RowProps = {
  headers: Header[]
  row: OrdersTableDataItem
  className?: string
  onClick: TableRowProps['onClick']
  to: string | undefined | null
  sqaPrefix?: string
  sqaSuffix?: string
}

const ALL_TABLE_HEADERS: RowProps['headers'] = [
  {
    id: 'address',
    label: 'Address',
    align: 'left',
    style: {width: '400px'},
    render: (orderData: OrdersTableDataItem) => {
      return (
        <div className='address-wrapper'>
          <Image
            width='40px'
            height='40px'
            asImg
            className='address-image'
            src={orderData?.photoUrl}
          />
          <Address twoLines sqaPrefix={orderData?.opsOrderId} address={formatAddress(orderData)} />
        </div>
      )
    }
  },
  {
    id: 'productGroup',
    label: 'Product Group',
    align: 'left',
    style: {width: '180px'},
    render: (orderData: OrdersTableDataItem) => {
      if (orderData?.productGroup) {
        return productGroupDictionary[orderData?.productGroup].name
      }
    }
  },
  {
    id: 'status',
    label: 'Status',
    align: 'left',
    style: {width: '130px'},
    render: (orderData: OrdersTableDataItem) => {
      return orderData.status.orderStatus
        ? ORDER_DISPLAY_STATUS_MAP[orderData.status.orderStatus]
        : NO_DATA
    }
  },
  {
    id: 'alerts',
    label: 'Alerts',
    align: 'left',
    style: {width: '160px'},
    render: (orderData: OrdersTableDataItem) => {
      let content
      const alerts = getAlertsByOrder(orderData)
      if (alerts.length > 0) {
        content = alerts.map((alert, index) => {
          return (
            <Tooltip
              key={`${orderData.opsOrderId}-${index}`}
              text={getOrderAlertTooltipTextByAlertType(alert, orderData)}
            >
              <div className='chip-wrapper'>
                <Chip label={alert} color={ORDER_DISPLAY_ALERTS_MAP[alert].color} />
              </div>
            </Tooltip>
          )
        })
      } else {
        content = NO_DATA
      }
      return content
    }
  },
  {
    id: 'created',
    label: 'Order Date',
    align: 'left',
    style: {width: '100px'},
    render: (orderData: OrdersTableDataItem) => orderData.created
  },
  // Ordered By used for org users
  {
    id: 'orderedBy',
    label: 'Ordered By',
    align: 'left',
    style: {width: '130px'},
    render: (orderData: OrdersTableDataItem) => {
      let content
      if (orderData?.orderedBy && !isEmpty(orderData.orderedBy)) {
        const {firstName = '', lastName = ''} = orderData.orderedBy
        content = firstName && lastName ? `${firstName} ${lastName}` : `${firstName}${lastName}`
      } else {
        content = NO_DATA
      }
      return content
    }
  },
  {
    id: 'clientTrackingId',
    label: 'Tracking ID',
    align: 'left',
    style: {width: '120px'},
    render: (orderData: OrdersTableDataItem) => orderData?.clientTrackingId ?? NO_DATA
  },
  {
    id: 'pdf',
    label: 'PDF',
    align: 'center',
    style: {width: '43px'},
    render: (orderData: OrdersTableDataItem) => {
      const {productOrderId, productType, status} = orderData
      return (
        <OrderDownload
          productOrderId={productOrderId}
          productType={productType}
          order={orderData}
          disabled={!(status.orderStatus === 'COMPLETED' || status.revision?.revisionRequested)}
        />
      )
    }
  }
]

function formatAddress({
  suppliedAddress,
  suppliedCityStateZip,
  suppliedStreet
}: OrdersTableDataItem) {
  let address
  if (suppliedAddress) {
    address = suppliedAddress
  } else if (suppliedCityStateZip && suppliedStreet) {
    // for old data, it may have suppliedStreet and suppliedCityStateZip
    const csz = suppliedCityStateZip.replace(',', '').split(' ')
    address = {
      street: suppliedStreet,
      // make it join to keep type consistent
      city: csz.slice(0, csz.length - 2).join(''),
      state: csz[csz.length - 2],
      zipcode: csz[csz.length - 1]
    }
  }
  return address
}

const OrdersTable = ({className, sqaPrefix}: Props) => {
  const history = useHistory()
  const location = useLocation()
  const customer = useSelector(customerSelector)
  const ordersData = useSelector(ordersTableDataSelector) || []
  const ordersStatus = useSelector(ordersStatusSelector)
  const {productGroups, isProductGroupsLoading} = useProductGroups({})
  const newPageLoading = ordersStatus === ORDERS_PAGING_LOADING || isProductGroupsLoading
  const updatedOrdersData = ordersData.map(order => {
    const productGroup = getProductGroup(order.productType, productGroups?.productGroups)

    return {
      ...order,
      productGroup
    }
  })

  const tableHeaders = useMemo(() => {
    return customer?.customerKey
      ? ALL_TABLE_HEADERS
      : ALL_TABLE_HEADERS.filter(headerObj => headerObj.id !== 'orderedBy')
  }, [customer?.customerKey])

  const handleOrderClick = useCallback(
    (to, order: OpsOrderType) => () => {
      if (to) {
        handleMixpanelLaunchedProductEvent(location.pathname, order)
        history.push(to)
      }
    },
    [history, location]
  )

  return (
    <OrdersTable.Styled className={classnames('orders-table', className)}>
      <MUITableContainer className={classnames({'page-loading': newPageLoading})}>
        <MUITable stickyHeader {...getSqaId('-table', sqaPrefix)}>
          <MUITableHead {...getSqaId('-table-head', sqaPrefix)}>
            <MUITableRow>
              {tableHeaders.map(
                ({
                  id,
                  label,
                  align,
                  style,
                  // @ts-ignore there is no sortable in tableHeaders
                  sortable = false
                }) => {
                  return (
                    <MUITableCell
                      className={classnames('header-sticky', {
                        sortable
                      })}
                      key={id}
                      align={align || 'left'}
                      style={style}
                    >
                      {label}
                    </MUITableCell>
                  )
                }
              )}
            </MUITableRow>
          </MUITableHead>
          {newPageLoading ? null : (
            <MUITableBody {...getSqaId('-table-body', sqaPrefix)}>
              {updatedOrdersData.map((orderRow, index) => {
                const to = getProductNavPath(orderRow)

                return (
                  <OrdersTable.BodyRow
                    key={orderRow?.opsOrderId}
                    headers={tableHeaders}
                    row={orderRow}
                    onClick={handleOrderClick(to, orderRow)}
                    to={getProductNavPath(orderRow)}
                    sqaPrefix={sqaPrefix}
                    sqaSuffix={index.toString()}
                  />
                )
              })}
            </MUITableBody>
          )}
        </MUITable>
      </MUITableContainer>
      {newPageLoading ? (
        <div className='table-loader'>
          <div>
            <CircularProgress />
          </div>
        </div>
      ) : null}
      <OrderTableFooter sqaPrefix={`${sqaPrefix}-footer`} newPageLoading={newPageLoading} />
    </OrdersTable.Styled>
  )
}

OrdersTable.BodyRow = React.memo(
  ({headers, row, className, onClick, to, sqaPrefix, sqaSuffix}: RowProps) => {
    return (
      <MUITableRow
        className={to ? 'clickable' : ''}
        onClick={onClick}
        {...getSqaId('row', sqaPrefix, sqaSuffix)}
      >
        {headers.map(header => {
          return (
            <MUITableCell
              className={className}
              key={`${header.id}-${row?.opsOrderId}`}
              align={header.align}
              {...getSqaId(`row-${header.id}-cell`, sqaPrefix)}
            >
              {header.render(row)}
            </MUITableCell>
          )
        })}
      </MUITableRow>
    )
  }
)

OrdersTable.Styled = styled.div`
  background-color: ${({theme}) => theme.colors.grayscale.white};
  padding: 16px 0 0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);

  .portal-MuiTableContainer-root {
    min-width: 800px;
    flex-grow: 1;

    &.page-loading {
      flex-grow: 0;
    }

    .portal-MuiTable-root {
      padding: 0 24px;
      table-layout: fixed;

      .portal-MuiTableCell-root {
        padding: 14px 4px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .portal-MuiTableCell-stickyHeader {
        width: 100%;
        background-color: ${({theme}) => theme.colors.grayscale.white};
        box-shadow: inset 0px -1px 0px ${({theme}) => theme.colors.stone.dark};
        padding-top: 8px;
        padding-bottom: 12px;

        &:first-child {
          padding-left: 8px;
        }

        &:last-child {
          padding-right: 8px;
        }
      }

      .address-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .address-image {
          border-radius: 6px;
          margin-right: 12px;
        }
      }

      .chip-wrapper {
        display: flex;
        margin-bottom: 2px;
      }

      // Icon Button needs this for centering due to flex display
      .portal-MuiTableCell-alignCenter {
        .icon-button {
          margin: auto;
        }
      }

      .portal-MuiTableCell-body:last-child {
        padding: 0;
      }

      .clickable {
        cursor: pointer;
      }
    }
  }

  .table-loader {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    /* inner div needed for IE11 */
    > div {
      display: flex;
      width: 48px;
    }
  }
`

export default OrdersTable
