import {DocumentUploadType} from 'types/orderTypes'

export type FileExtension = 'bmp' | 'doc' | 'docx' | 'gif' | 'jpeg' | 'pdf' | 'png' | 'txt' | 'xml'

interface OrderDocumentTypeItem {
  label: string
  allowedFileExtensions: FileExtension[]
}

export const ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE: Record<
  DocumentUploadType,
  OrderDocumentTypeItem
> = {
  APPRAISAL: {
    label: 'Appraisal',
    allowedFileExtensions: ['pdf', 'xml']
  }
}
