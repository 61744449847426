// Style
import styled from 'styled-components'

// Core
import {FormHelperText} from '@material-ui/core'
import UploadButton from 'components/UploadButton'
import React, {Fragment, useState} from 'react'

// Components, actions, models, etc.
import {Field, useForm} from 'react-final-form'

import {uploadAppraisal} from 'services/uploadServices'
import FormField from './FormField'

const MAX_FILE_SIZE = 15 * 1000 * 1000
const FILE_SIZE_TEXT = `${MAX_FILE_SIZE / 1000 / 1000}MB`

const ERROR_MESSAGE = {
  UPLOADING: 'Wait for the upload to complete to place your order.',
  UPLOAD_FAILED: 'Upload failed, please try again.',
  FILE_SIZE_ERROR: `File size exceeds limit. Maximum file size is ${FILE_SIZE_TEXT}`,
  EMPTY: 'Please upload your appraisal.'
}

type UploadFormFieldProps = {
  field: string
  label: string
  className?: string
  optional?: boolean
  opsOrderId?: string
}
const UploadFormField = ({field, label, className, optional, opsOrderId}: UploadFormFieldProps) => {
  const [status, setStatus] = useState<keyof typeof ERROR_MESSAGE | 'UPLOADED'>('EMPTY')
  const form = useForm()

  const getDocument = async (file: FileList[number]) => {
    let document
    if (file.size > MAX_FILE_SIZE) {
      setStatus('FILE_SIZE_ERROR')
    } else {
      setStatus('UPLOADING')

      try {
        document = await uploadAppraisal(file, opsOrderId)
        setStatus('UPLOADED')
      } catch (e) {
        setStatus('UPLOAD_FAILED')
      }
    }

    form.change(field, document)
  }

  return (
    <FormField label={label} className={className}>
      <Field name={field}>
        {({input, meta}) => {
          const fileName = input.value.fileName
          return (
            <Fragment>
              <UploadFormField.Styled>
                <UploadButton
                  onSelectFiles={async file => {
                    // trigger meta.touched
                    input.onFocus()
                    await getDocument(file[0])
                    input.onBlur()
                  }}
                  uploading={status === 'UPLOADING'}
                  fileName={fileName}
                />
                <div>
                  <p>Supports XML, PDF</p>
                  <p>Maximum File Size {FILE_SIZE_TEXT}</p>
                </div>
              </UploadFormField.Styled>
              <FormHelperText error>
                {meta.touched &&
                  (optional ? status !== 'EMPTY' : meta.error) &&
                  status !== 'UPLOADED' &&
                  ERROR_MESSAGE[status]}
              </FormHelperText>
            </Fragment>
          )
        }}
      </Field>
    </FormField>
  )
}

UploadFormField.Styled = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;

  p {
    margin: 0;
    color: ${({theme}) => theme.colors.grayscale.gray};
    font-size: 0.75rem;
    line-height: 18px;
  }

  + .portal-MuiFormHelperText-root {
    position: relative;
  }
`

export default UploadFormField
