// ROOT REDUCER
export const RESET_APP = 'RESET_APP' as const

// MODALS
export const OPEN_MODAL = 'OPEN_MODAL' as const
export const CLOSE_MODAL = 'CLOSE_MODAL' as const
export const REMOVE_MODAL = 'REMOVE_MODAL' as const
export const RESET_MODAL = 'RESET_MODAL' as const

// AUTH
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING' as const
export const SET_AUTH_LOADED = 'SET_AUTH_LOADED' as const
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR' as const
export const SET_BASIC_AUTH_TOKEN = 'SET_BASIC_AUTH_LOADED' as const

// PROPERTY
export const RESET_PROPERTY = 'RESET_PROPERTY' as const
export const SET_PROPERTY_LOADING = 'SET_PROPERTY_LOADING' as const
export const SET_PROPERTY_LOADED = 'SET_PROPERTY_LOADED' as const
export const SET_PROPERTY_ERROR = 'SET_PROPERTY_ERROR' as const
export const SET_PROPERTY_SEARCHING = 'SET_PROPERTY_SEARCHING' as const

// ORDERS
export const RESET_ORDERS = 'RESET_ORDERS' as const
export const SET_ORDERS_LOADING = 'SET_ORDERS_LOADING' as const
export const SET_ORDERS_PAGING_LOADING = 'SET_ORDERS_PAGING_LOADING' as const
export const SET_ORDERS_LOADED = 'SET_ORDERS_LOADED' as const
export const SET_ORDERS_ERROR = 'SET_ORDERS_ERROR' as const
export const SET_ORDERS_BY_ADDRESS_LOADING = 'SET_ORDERS_BY_ADDRESS_LOADING' as const
export const SET_ORDERS_BY_ADDRESS_LOADED = 'SET_ORDERS_BY_ADDRESS_LOADED' as const
export const SET_ORDERS_BY_ADDRESS_ERROR = 'SET_ORDERS_BY_ADDRESS_ERROR' as const
export const RESET_ORDERS_BY_ADDRESS = 'RESET_ORDERS_BY_ADDRESS' as const
export const SET_PER_PAGE = 'SET_PER_PAGE' as const
export const SET_ORDER_BY_OPS_ORDER_ID_LOADING = 'SET_ORDER_BY_OPS_ORDER_ID_LOADING' as const
export const SET_ORDER_BY_OPS_ORDER_ID_LOADED = 'SET_ORDER_BY_OPS_ORDER_ID_LOADED' as const
export const SET_ORDER_BY_OPS_ORDER_ID_ERROR = 'SET_ORDER_BY_OPS_ORDER_ID_ERROR' as const
export const RESET_ORDERS_BY_OPS_ORDER_ID = 'RESET_ORDERS_BY_OPS_ORDER_ID' as const
export const UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID = 'UPDATE_ORDER_STATUS_BY_OPS_ORDER_ID' as const

// Orders Pagination
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE' as const
export const SET_ORDER_PAGINATION_HISTORY = 'SET_ORDER_PAGINATION_HISTORY' as const

// USER
export const SET_USER_LOADING = 'SET_USER_LOADING' as const
export const SET_USER_LOADED = 'SET_USER_LOADED' as const
export const SET_USER_ERROR = 'SET_USER_ERROR' as const

// WALLET
export const SET_WALLET_LOADING = 'SET_WALLET_LOADING' as const
export const SET_WALLET_LOADED = 'SET_WALLET_LOADED' as const
export const SET_WALLET_ERROR = 'SET_WALLET_ERROR' as const

// CUSTOMER
export const SET_CUSTOMER_LOADING = 'SET_CUSTOMER_LOADING' as const
export const SET_CUSTOMER_LOADED = 'SET_CUSTOMER_LOADED' as const
export const SET_CUSTOMER_ERROR = 'SET_CUSTOMER_ERROR' as const

// DOCUMENTS
export const SET_DOCUMENTS_LOADED = 'SET_DOCUMENTS_LOADED' as const
export const SET_DOCUMENTS_LOADING = 'SET_DOCUMENTS_LOADING' as const
export const SET_DOCUMENTS_ERROR = 'SET_DOCUMENTS_ERROR' as const
export const RESET_DOCUMENTS = 'RESET_DOCUMENTS' as const
export const SET_UPLOADED_DOCUMENTS = 'SET_UPLOADED_DOCUMENTS' as const
export const DELETE_UPLOADED_DOCUMENTS = 'DELETE_UPLOADED_DOCUMENTS' as const
