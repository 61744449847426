import {
  FileExtension,
  ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE
} from 'constants/orderDocumentConstants'
import {
  FILE_SIZE_EXCEEDS_LIMIT_MESSAGE,
  MAX_FILE_SIZE,
  MISMATCHED_FILE_EXT_START,
  REQUIRED_DOCUMENTS,
  REQUIRED_FIELD_MSG,
  REQUIRED_UPLOAD_DOCUMENT
} from 'constants/validationErrorMessagesConstants'
import {DocumentUploadType} from 'types/orderTypes'
import {OrderFormType} from 'types/productTypes'

export const required = (value: string) => {
  if (typeof value === 'string') {
    value = value.trim()
  }
  return value ? undefined : REQUIRED_FIELD_MSG
}

export const validateRequiredDocumentType = (
  documentType: DocumentUploadType,
  fileName: string,
  fileSize: number
) => {
  if (!documentType) {
    return REQUIRED_FIELD_MSG
  }
  const allowedFileExtensions =
    ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE[documentType].allowedFileExtensions

  const fileExtension = (fileName.toLowerCase().split('.').pop() as FileExtension) ?? ''
  if (!allowedFileExtensions.includes(fileExtension)) {
    return `${MISMATCHED_FILE_EXT_START} ${
      allowedFileExtensions.length === 1 ? '' : 'one of:'
    } ${allowedFileExtensions.join(', ')}`
  } else if (fileSize > MAX_FILE_SIZE) {
    return FILE_SIZE_EXCEEDS_LIMIT_MESSAGE
  } else if (fileSize === 0) {
    return `The ${documentType} named ${fileName} is empty.  Try again by choosing a different file.`
  }
}

export const validateUploadedDocuments = (
  requiredDocuments: DocumentUploadType[],
  uploadedDocuments: OrderFormType['documents'] = [],
  isUploadDocumentRequired: boolean = false
): string | undefined => {
  let error
  if (isUploadDocumentRequired && uploadedDocuments.length === 0) {
    error = REQUIRED_UPLOAD_DOCUMENT
  } else {
    for (let i = 0; i < requiredDocuments.length; i++) {
      if (
        !uploadedDocuments.find(
          uploadedDocument => uploadedDocument.documentType === requiredDocuments[i]
        )
      ) {
        error = REQUIRED_DOCUMENTS
        break
      }
    }
  }
  return error
}
