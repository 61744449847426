import {ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE} from 'constants/orderDocumentConstants'
import {opsApi} from 'services/apis'
import type {
  AddUnassociatedDocumentResponse,
  DocumentUploadType,
  DocumentUploadSupportedType,
  UploadedDocumentRequest
} from 'types/orderTypes'
import {OrderFormDocument} from 'types/productTypes'

export const filterConditionallyRequiredDocuments = (
  requiredDocuments: DocumentUploadType[],
  payload: {
    loanPurposeType: string
  }
): DocumentUploadType[] => {
  let filteredRequiredDocuments = requiredDocuments
  // for the current state of the app we are not supporting document type "Purchase Agreement"
  // we might support them in the future. Hence we are ignoring the TS errors being thrown.
  // @ts-ignore
  if (requiredDocuments?.includes('PURCHASE_AGREEMENT') && payload.loanPurposeType !== 'Purchase') {
    filteredRequiredDocuments = requiredDocuments.filter(
      // @ts-ignore
      requiredDocument => requiredDocument !== 'PURCHASE_AGREEMENT'
    )
  }
  return filteredRequiredDocuments
}

export const getDocumentTypeOptions = () =>
  Object.keys(ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE).map(key => ({
    label: ORDER_DOCUMENT_CONFIG_MAP_BY_DOCUMENT_TYPE[key as DocumentUploadType].label,
    value: key
  }))

export const uploadDocument = async (
  file: OrderFormDocument['file'],
  documentType: DocumentUploadType,
  orderId?: string
): Promise<UploadedDocumentRequest> => {
  try {
    const fileName = file.name

    const getUpdatedDocumentType = (
      documentType: DocumentUploadType
    ): DocumentUploadSupportedType => {
      let result
      if (documentType === 'APPRAISAL') {
        result = file.type === 'application/pdf' ? 'APPRAISAL_PDF' : 'APPRAISAL_MISMO'
      } else {
        result = documentType
      }
      return result as DocumentUploadSupportedType
    }

    const updatedocumentType = getUpdatedDocumentType(documentType)
    const documentsUrl = orderId ? `orders/${orderId}/documents` : 'orders/documents'

    const {uploadUrl, storageKey} = await opsApi.post<AddUnassociatedDocumentResponse>(
      documentsUrl,
      {
        fileName,
        documentType: updatedocumentType
      },
      {}
    )

    // wait the file upload to s3
    // this is a special request, must set no 'Content-Type' in the header
    // and has no authentications
    await fetch(`${uploadUrl}`, {
      method: 'PUT',
      body: file,
      headers: {'Content-Type': ''}
    })
      .then(response => {
        if (response.status >= 400 && response.status < 600) {
          throw new Error(
            `The ${documentType} named ${fileName} failed to upload.  Try again by re-adding the file to the order form.`
          )
        }
        return response
      })
      .catch(error => {
        console.error(error)
        throw error
      })

    return {
      storageKey
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
