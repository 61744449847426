import React, {Fragment} from 'react'
import styled from 'styled-components'
import {useFormState} from 'react-final-form'

import Button from 'components/Button'
import Icon from 'components/Icon'
import {required} from 'services/formValidationHelpers'
import useSupportedProductGroups from 'hooks/useSupportedProductGroups'
import {
  getProduct,
  getProductGroupDataByProductGroup,
  calculateProductTotalPrice,
  getProductsByProductGroup
} from 'services/productsServices'
import {getDeliveryOptionByTurnTime} from 'services/deliveryOptionsServices'
import {ProductGroupType} from 'types/productTypes'
import useProductGroups from 'hooks/useProductGroups'
import {propertyDataAddressSelector} from 'selectors'
import {useSelector} from 'react-redux'
import AgreementTerms from 'components/AgreementTerms'
import {REQUIRED_FIELD_MSG} from 'constants/validationErrorMessagesConstants'
import {productGroupDictionary} from 'constants/productCardConstants'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt'

interface CheckoutSummaryProps {
  productGroup: ProductGroupType
  image: string
  isSubmitting?: boolean
  shouldDisablePlaceOrderButton: boolean
  onPlaceOrder: () => any
}

const CheckoutSummary = ({
  productGroup,
  image,
  isSubmitting,
  shouldDisablePlaceOrderButton,
  onPlaceOrder
}: CheckoutSummaryProps) => {
  const address = useSelector(propertyDataAddressSelector)
  const supportedProductGroups = useSupportedProductGroups(address)
  const {values} = useFormState()
  const {productType: productCode, turnTime, paymentMethod} = values
  const shouldIncludeTaxAndFeeCharges = ['PAYMENT_LINK', 'LENDER_CARD'].includes(paymentMethod)
  const {product, prices: deliveryOptions, description} =
    getProduct({
      productId: productCode,
      productGroups: supportedProductGroups,
      productGroupId: productGroup
    }) ?? {}

  const getProductName = () => {
    let productName = ''
    const hasMultipleProductsInProductGroup =
      (getProductsByProductGroup(productGroup, supportedProductGroups) ?? []).length > 1
    if (product) {
      if (hasMultipleProductsInProductGroup) {
        productName = `${description} (${productGroupDictionary[productGroup].name})`
      } else {
        productName = productGroupDictionary[productGroup].title
      }
    }
    return productName
  }

  const productName = getProductName()
  const {productGroups: products} = useProductGroups(address)

  const {usesLocationBasedPricing} =
    getProductGroupDataByProductGroup(productGroup, supportedProductGroups) ?? {}

  const deliveryOptionSelected = getDeliveryOptionByTurnTime(deliveryOptions ?? [], turnTime)
  const shouldRenderPricesUnavailable = products?.addressWarning && usesLocationBasedPricing
  const price =
    products?.addressWarning && usesLocationBasedPricing
      ? ''
      : deliveryOptionSelected?.estimate
      ? calculateProductTotalPrice(
          deliveryOptionSelected?.estimate,
          deliveryOptionSelected.creditCardFee
        )
      : ''

  const deliveryDays = (deliveryOptionSelected?.turnTime ?? 0) / 24

  return (
    <CheckoutSummary.Styled>
      <div className='product'>
        <div className='product-details'>
          <h4 className='product-details-name'>{productName}</h4>
          {!!deliveryDays && (
            <Fragment>
              <h5 className='product-details-delivery-days'>
                <Icon icon='event_note' /> {deliveryDays} Day Delivery
              </h5>
            </Fragment>
          )}
          {product === 'CLEARPROP' && (
            <span className='instant-delivery-wrapper'>
              <OfflineBoltIcon className='offline_bolt_icon' />{' '}
              <span className='instant-delivery-title'>Instant Delivery</span>
            </span>
          )}
        </div>
        <img src={image} className='product-image' alt='' />
      </div>
      <div className='product-total'>
        {shouldRenderPricesUnavailable ? (
          <p className='product-price-unavailable'>Pricing to be confirmed after order placement</p>
        ) : (
          <Fragment>
            <h4 className='product-total-price'>
              Total
              <span>${price}</span>
            </h4>
            {shouldIncludeTaxAndFeeCharges && (
              <p className='product-extra-charges'>Includes taxes and transaction fees</p>
            )}
          </Fragment>
        )}
      </div>
      <div className='agreement'>
        <AgreementTerms
          validate={value => (required(value) ? REQUIRED_FIELD_MSG : undefined)}
          key='agreement'
          field='agreement'
          classname='agreement-terms'
          sqaPrefix='agreement-terms'
        />
      </div>
      <PlaceOrderButton
        className='btn-place-order'
        title={isSubmitting ? 'Placing Order...' : 'Place Order'}
        disabled={shouldDisablePlaceOrderButton}
        onClick={onPlaceOrder}
      />
    </CheckoutSummary.Styled>
  )
}

CheckoutSummary.Styled = styled.div`
  width: 100%;

  .product,
  .product-total {
    border-bottom: 1px solid ${({theme}) => theme.colors.stone.light};
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .product {
    align-items: start;
    display: flex;

    &-details {
      width: 100%;

      &-name {
        flex: 1 1 auto;
        margin: 0 7px 7px 0;
        font-size: 18px;
      }

      &-delivery-days {
        align-items: center;
        color: ${({theme}) => theme.colors.grayscale.gray};
        display: flex;
        font-weight: 400;
        gap: 7px;
      }
    }

    &-image {
      width: 118px;
      height: 72px;
      flex: 0 0 auto;
    }

    &-total-price {
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
    }

    &-price-unavailable {
      color: ${({theme}) => theme.colors.grayscale.gray};
      font-weight: 500;
      margin: 0;
    }

    &-extra-charges {
      color: ${({theme}) => theme.colors.grayscale.gray};
      font-size: 0.75rem;
      margin: 0 0 -10px 0;
      text-align: right;
      padding-top: 5px;
    }
  }

  .agreement {
    margin-bottom: 20px;
    display: flex;

    .portal-MuiFormControlLabel-root {
      align-items: start;
    }

    .agreement-terms-text {
      display: inline;
    }

    .portal-MuiButton-label {
      padding-bottom: 3px;
    }

    .portal-MuiFormHelperText-root {
      position: relative;
      white-space: normal;
    }

    .Mui-error {
      top: 0;
    }
  }

  .instant-delivery-wrapper {
    display: flex;
    align-items: center;
    .offline_bolt_icon {
      width: 13px;
      height: 13px;
      color: ${({theme}) => theme.colors.citrine.base};
      margin-right: 6px;
    }

    .instant-delivery-title {
      font-size: 0.875rem;
    }
  }

  .btn-place-order {
    height: 40px;
  }
`

const PlaceOrderButton = styled(Button)`
  width: 100%;
`

export default CheckoutSummary
