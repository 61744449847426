import {opsApi} from 'services/apis'
import type {
  AddUnassociatedDocumentRequestBody,
  AddUnassociatedDocumentResponse
} from 'types/orderTypes'

let controller: AbortController | undefined
export const uploadAppraisal = async (file: FileList[number], opsOrderId: string | undefined) => {
  const fileName = file.name
  const documentType = file.type === 'application/pdf' ? 'APPRAISAL_PDF' : 'APPRAISAL_MISMO'
  const cdaAppraisalUploadUrl = opsOrderId ? `orders/${opsOrderId}/documents` : 'orders/documents'

  const payload: AddUnassociatedDocumentRequestBody = {
    fileName,
    documentType
  }

  // stop previous request
  if (controller) {
    controller.abort()
  }

  controller = new AbortController()
  const {uploadUrl, storageKey} = await opsApi.post<AddUnassociatedDocumentResponse>(
    cdaAppraisalUploadUrl,
    payload,
    {},
    {signal: controller.signal}
  )

  // wait the file upload to s3
  // this is a special request, must set no 'Content-Type' in the header
  // and has no authentications
  await fetch(uploadUrl, {
    method: 'PUT',
    body: file,
    headers: {'Content-Type': ''},
    signal: controller.signal
  })

  return {
    storageKey,
    documentType,
    fileName,
    fileSize: file.size
  }
}
