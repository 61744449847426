import Landing from 'views/Landing'
import Orders from 'views/Orders'
import Property from 'views/Property'
import ClearPropContainer from 'components/ClearPropContainer'
import OrderDetails from 'views/OrderDetails'
import FederatedLoginLanding from 'views/FederatedLoginLanding'
import RedirectRoute from 'components/RedirectRoute'
import Admin from 'views/Admin'

export const appRoutes = [
  {
    label: 'Home',
    path: '/products',
    Component: Landing,
    authenticated: false
  },
  // only available for logged in users
  {
    label: 'ClearProp',
    path: '/orders/clearprop/:opsOrderId',
    Component: ClearPropContainer,
    authenticated: true
  },
  // only available for logged in users
  {
    label: 'CDA',
    path: '/orders/cda/:opsOrderId',
    Component: OrderDetails,
    authenticated: true
  },
  {
    label: 'AVM',
    path: '/orders/avm/:opsOrderId',
    Component: OrderDetails,
    authenticated: true
  },
  // only available for logged in users
  {
    label: 'All Properties',
    path: '/orders',
    Component: Orders,
    authenticated: true
  },
  {
    label: 'Admin',
    path: '/admin',
    Component: Admin,
    authenticated: true
  },
  {
    label: 'Properties',
    path: '/property-overview',
    Component: Property,
    authenticated: false
  },
  {
    label: '',
    path: '/social-auth-tokens',
    Component: FederatedLoginLanding,
    authenticated: false
  },
  {
    label: '',
    path: '/redirect',
    Component: RedirectRoute,
    authenticated: false
  }
]

export type ClearPropRoute = {opsOrderId: string}
export type OrdersRoute = {product: string}
export const ALL_ORDERS_PATH = '/orders'
