// Styles
import styled from 'styled-components/macro'

// Core
import React from 'react'
import type {ReactNode} from 'react'

// 3rd-party
import classnames from 'classnames'
import {getSqaId} from 'services/testingServices'

type Props = {
  className?: string
  label: ReactNode
  value: ReactNode
  sqaPrefix?: string
}

const LabelValuePair = ({className, label, value, sqaPrefix}: Props) => {
  return (
    <LabelValuePair.Styled className={classnames('label-value-pair', className)}>
      <div className='label' {...getSqaId('label', sqaPrefix)}>
        {label}
      </div>
      <div className='value' {...getSqaId('value', sqaPrefix)}>
        {value}
      </div>
    </LabelValuePair.Styled>
  )
}

LabelValuePair.Styled = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 0;

  .label {
    width: 188px;
    flex-shrink: 0;
    color: ${({theme}) => theme.colors.grayscale.gray};
  }

  .value {
    color: ${({theme}) => theme.colors.grayscale.black};
    line-height: 22px;
  }
`

export default LabelValuePair
