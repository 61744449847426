import {useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {orderByOpsOrderIdSelector} from 'selectors'
import {opsApi} from 'services/apis'
import {isCDAOrder} from 'services/orderDetailsServices'
import useSWR from 'swr'
import {GetDocumentsByOpsOrderIdResponse} from 'types/orderTypes'

export default function useGetDocumentByOpsOrderId() {
  const order = useSelector(orderByOpsOrderIdSelector)
  const {opsOrderId} = useParams<{opsOrderId: string}>()
  const getUrl = `orders/${opsOrderId}/documents`
  const {data, error, isLoading} = useSWR(
    isCDAOrder(order) ? getUrl : null,
    () => opsApi.get<GetDocumentsByOpsOrderIdResponse>(getUrl),
    {revalidateOnFocus: false, shouldRetryOnError: false}
  )

  return {data, error, isLoading}
}
