// Core
import React from 'react'

// Components, services, constants
import Card from 'components/Card'
import FlatList from 'components/FlatList'
import LabelValuePair from 'components/LabelValuePair'
import {PurposeOptions} from 'constants/loanDetailsConstants'
import type {OpsOrderType} from 'types/orderTypes'

const LoanDetailsCard = ({order}: {order: OpsOrderType}) => {
  const loanDetailsData = [
    {
      key: 'clientLoanNumber',
      content: <LabelValuePair label='Loan #' value={order.clientLoanNumber} />
    },
    {
      key: 'clientLenderName',
      content: <LabelValuePair label='Lender/Client' value={order.clientLenderName} />
    },
    {
      key: 'borrowerName',
      content: <LabelValuePair label='Borrower Name' value={order.borrowerName} />
    },
    {
      key: 'investor',
      content: <LabelValuePair label='Investor' value={order.investor} />
    },
    {
      key: 'purpose',
      content: (
        <LabelValuePair
          label='Purpose'
          value={PurposeOptions.find(item => item.value === order.purpose)?.label}
        />
      )
    }
  ]

  return (
    <Card>
      <h4>Loan Details</h4>
      <FlatList items={loanDetailsData} withDividers />
    </Card>
  )
}

export default LoanDetailsCard
