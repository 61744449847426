import useSWR, {SWRConfiguration} from 'swr'
import {useSelector} from 'react-redux'

import {getProductGroups} from 'services/productsServices'
import {isUserAuthenticatedSelector} from 'selectors/authSelectors'
import {AddressType} from 'types/propertyTypes'
import {ProductsResponse} from 'types/productTypes'

const useProductGroups = (address?: AddressType, swrConfig?: SWRConfiguration) => {
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)

  const {data, error, isLoading} = useSWR<ProductsResponse>(
    isUserAuthenticated ? address || 'non-address' : null,
    getProductGroups,
    {revalidateOnFocus: false, shouldRetryOnError: false, ...swrConfig}
  )

  return {
    isProductGroupsLoading: isLoading,
    productGroups: data,
    productGroupsError: error
  }
}

export default useProductGroups
