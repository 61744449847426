// Styles
import styled from 'styled-components/macro'

// Core
import React, {useState} from 'react'

// Components, services, constants
import IconButton from 'components/IconButton'
import {DOWNLOADED_PDF} from 'constants/mixpanelConstants'
import {trackMixpanelEvent} from 'services/mixpanel'
import {downloadPdf, getExportedClearPropPdf} from 'services/pdfExportServices'
import type {ProductType} from 'types/productTypes'
import {
  CDA,
  CDA_MLS,
  CLEARPROP,
  CLEAR_AVM_FULLY_SUPPORTED,
  CLEAR_AVM_VALUES_ONLY
} from 'constants/productCardConstants'
import type {OrdersTableDataItem} from 'selectors'
import CircularProgress from '@material-ui/core/CircularProgress'
import {resetDocumentsState} from 'actions/documentsActions'
import {useAppDispatch} from 'services/store'

// 3rd party
import classnames from 'classnames'
import {downloadPdfByDocumentType} from 'services/ordersServices'

type Props = {
  className?: string
  productOrderId: string
  productType: ProductType
  order: OrdersTableDataItem
  disabled?: boolean
}

const OrderDownload = ({
  className,
  productOrderId,
  order,
  productType,
  disabled = false
}: Props) => {
  const [pdfLoading, setPdfLoading] = useState(false)
  const dispatch = useAppDispatch()

  function handleDownloadIconClick(
    orderId: string,
    productType: ProductType
  ): React.MouseEventHandler<HTMLButtonElement> {
    return async se => {
      se.preventDefault()
      se.stopPropagation()
      trackMixpanelEvent(DOWNLOADED_PDF, {productType, order})
      setPdfLoading(true)

      try {
        if (productType === CLEARPROP) {
          const {filename, blob} = await getExportedClearPropPdf(orderId)
          downloadPdf(filename, blob)
        } else if (productType === CDA || productType === CDA_MLS) {
          dispatch(resetDocumentsState())
          const {opsOrderId} = order
          if (opsOrderId) {
            await downloadPdfByDocumentType(order.opsOrderId, 'DELIVERABLE_PDF')
          }
        } else if (
          productType === CLEAR_AVM_FULLY_SUPPORTED ||
          productType === CLEAR_AVM_VALUES_ONLY
        ) {
          await downloadPdfByDocumentType(order.opsOrderId, 'DELIVERABLE_PDF')
        }
      } catch (e) {
        console.error(e)
      }

      setPdfLoading(false)
    }
  }

  return (
    <OrderDownload.Styled className={classnames('order-download', className)}>
      {pdfLoading ? (
        <div className='load-wrap'>
          <div>
            <CircularProgress />
          </div>
        </div>
      ) : (
        <IconButton
          className={classnames('icon-button', {'icon-button-disabled': disabled})}
          icon='file_download'
          disabled={disabled}
          onClick={handleDownloadIconClick(productOrderId, productType)}
        />
      )}
    </OrderDownload.Styled>
  )
}

OrderDownload.Styled = styled.div`
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .load-wrap {
    width: 24px;
    height: 24px;
    /* inner div needed for IE11 */
    > div {
      display: flex;
      width: 24px;
    }
  }

  .icon-button {
    color: ${({theme}) => theme.colors.stone.dark};
    flex: 0 0 auto;
    padding: 8px;

    &-disabled {
      color: ${({theme}) => theme.colors.stone.light};
    }
  }
`

export default OrderDownload
