import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import {getOrderProgressStepNames} from 'services/ordersServices'

const StatusProgressBar = ({step}: {step: number}) => {
  const stepNames = getOrderProgressStepNames()

  return (
    <StatusProgressBar.Styled>
      {stepNames.map((stepName, i) => {
        return (
          <div
            className={classnames(
              'progress-item',
              {active: i === step},
              {done: i < step},
              {todo: i > step}
            )}
            key={i}
          >
            <div className='progress-bar' />
            <p>{stepName}</p>
          </div>
        )
      })}
    </StatusProgressBar.Styled>
  )
}

StatusProgressBar.Styled = styled.div`
  display: flex;
  column-gap: 2px;
  justify-content: center;
  margin-bottom: 40px;

  .progress-item {
    .progress-bar {
      background: ${({theme}) => theme.colors.emerald.light};
      height: 6px;
      width: 170px;
      margin-bottom: 6px;
    }

    &:first-child .progress-bar {
      border-radius: 100px 0px 0px 100px;
    }

    &:last-child .progress-bar {
      border-radius: 0px 100px 100px 0px;
    }

    p {
      text-align: center;
      margin: 0;
    }
  }

  .todo {
    p {
      color: ${({theme}) => theme.colors.grayscale.gray};
    }

    .progress-bar {
      background: ${({theme}) => theme.colors.stone.light};
    }
  }

  .active {
    p {
      font-weight: 600;
    }

    .progress-bar {
      background: ${({theme}) => theme.colors.emerald.base};
    }
  }
`
export default StatusProgressBar
