import {pdfExportApi} from './apis'
import {reportSections} from 'constants/pdfExportConstants'
import type {DownloadDocumentMap} from 'hooks/useDownloadDocumentsMap'
import {downloadPdfByDocumentType} from './ordersServices'

export function getExportedClearPropPdf(orderId: string) {
  const queryParams = reportSections.map(section => `reportSections=${section}`).join('&')
  return pdfExportApi.get(`clearprop/${orderId}/export/pdf?${queryParams}`)
}

export function downloadPdf(filename: string, blob: BlobPart) {
  const newBlob = new Blob([blob], {type: 'application/pdf'})

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob, filename)
  } else {
    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    const anchor = document.createElement('a')
    anchor.setAttribute('download', filename)
    anchor.setAttribute('target', '_blank')
    anchor.setAttribute('href', data)
    anchor.click()

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data)
    }, 100)
  }
}

export const CDADownloadList: {label: string}[] = [{label: 'Download Appraisal'}]

export const getCDADownloadMenuItems = (
  downloadList: {label: string}[],
  opsOrderId: string,
  documentMap?: DownloadDocumentMap
): {label: string; onClick: () => Promise<void>}[] => {
  return documentMap && Object.keys(documentMap).length
    ? downloadList.map(({label}) => ({
        label,
        onClick: () => downloadPdfByDocumentType(opsOrderId, documentMap?.APPRAISAL?.documentType)
      }))
    : []
}
