import mixpanel from 'mixpanel-browser'
import {COOKIE_PREFERENCE_ANALYTICS} from 'constants/appConstants'
import {handleMixpanelSuperPropertiesAndUser} from 'services/mixpanel'
import {fireCustomEvent} from 'services/customEventService'

export const getAnalyticsCookieValue = () => {
  const analyticsCookieValue = getCookieValue(COOKIE_PREFERENCE_ANALYTICS)
  const preference = analyticsCookieValue === 'true'
  return preference
}

export const setAnalyticsCookieValue = (value: string) => {
  // We want to fire the correct Mixpanel function to control in app tracking
  if (value) {
    // First we opt the user in, then we set the super properties and identify the user
    // (if needed)
    mixpanel.opt_in_tracking()
    handleMixpanelSuperPropertiesAndUser()
  } else {
    mixpanel.opt_out_tracking()
  }

  // We want to fire off custom event for Clear Prop (and any other children apps) to listen for
  fireCustomEvent('updateTrackingPreferences', value)

  // Finally we want to set the value of the cookie so we have access to it on refresh
  setCookieValue(COOKIE_PREFERENCE_ANALYTICS, value)
}

export const getCookieValue = (cookieName: string) => {
  const cookieValue = document.cookie.match(`;\\s*${cookieName}=([^;]+)`)
  return cookieValue ? cookieValue[1] : null
}

export const setCookieValue = (cookieName: string, value: string) => {
  document.cookie = `${cookieName}=${value}`
}
