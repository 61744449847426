// Styles
import styled from 'styled-components'

// Core
import React, {useState} from 'react'

// Components
import Modal from 'components/Modal'
import Button from 'components/Button'
import UploadFormField from 'components/UploadFormField'
import TextInput from 'components/TextInput'
import {
  MAX_ORDER_STATUS_CHANGE_REASON_LIMIT,
  requiredValidationRules
} from 'constants/validationRulesConstants'
import {validateForm} from 'services/formValidationHelpers'
import {requestCancelOrder, requestOnHold, requestRevision} from 'services/opsServices'
import {useAppDispatch} from 'services/store'
import {getOrderByOpsOrderId} from 'actions/ordersActions'

// Third party
import {Field, Form} from 'react-final-form'
import {
  MAX_STRING_LENGTH_END,
  MAX_STRING_LENGTH_START
} from 'constants/validationErrorMessagesConstants'

const MODAL_META_MAP = {
  ON_HOLD: {
    title: 'Place On Hold',
    subTitle: 'Order will be not be processed till hold is released.',
    reasonTitle: 'Hold Reason',
    canUploadAppraisal: true,
    submitButton: {title: 'Place on Hold', variant: 'primary'}
  },
  CANCELLATION: {
    title: 'Request Cancellation',
    subTitle: 'Once approved, cancellation details will be sent to your email.',
    reasonTitle: 'Cancellation Reason',
    canUploadAppraisal: false,
    submitButton: {title: 'Request Cancellation', variant: 'warning'}
  },
  REVISION: {
    title: 'Request Revision',
    subTitle:
      'Requests are usually processed within two business days. We will email you when an update is available.',
    reasonTitle: 'Provide more information about the request',
    canUploadAppraisal: true,
    submitButton: {title: 'Request Revision', variant: 'primary'}
  }
} as const

type ORDER_STATUS_MODE = keyof typeof MODAL_META_MAP

type ChangeOrderStatusModalProps = {
  handleClose: () => void
  handleExited: () => void
  open: boolean
  mode: ORDER_STATUS_MODE
  opsOrderId: string
}

type FormValue = {reason?: string; document?: string}
const ChangeOrderStatusModal = ({
  open: isOpen,
  handleClose,
  handleExited,
  mode,
  opsOrderId
}: ChangeOrderStatusModalProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useAppDispatch()
  const {title, subTitle, reasonTitle, submitButton, canUploadAppraisal} = MODAL_META_MAP[mode]
  const onSubmit = async ({reason}: {reason: string}) => {
    setIsSubmitting(true)

    const payload = {reason}
    if (mode === 'REVISION') {
      await requestRevision(opsOrderId, payload)
    } else if (mode === 'ON_HOLD') {
      await requestOnHold(opsOrderId, payload)
    } else if (mode === 'CANCELLATION') {
      await requestCancelOrder(opsOrderId, payload)
    }

    dispatch(getOrderByOpsOrderId(opsOrderId))
    handleClose()
    setIsSubmitting(false)
  }

  const validate = (values: FormValue) => {
    return validateForm(
      {
        reason: [
          ...requiredValidationRules,
          {
            rule: 'maxStringLength',
            ruleParams: [MAX_ORDER_STATUS_CHANGE_REASON_LIMIT],
            errorMsg: `${MAX_STRING_LENGTH_START} ${MAX_ORDER_STATUS_CHANGE_REASON_LIMIT} ${MAX_STRING_LENGTH_END}`
          }
        ]
      },
      values
    )
  }

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({handleSubmit, dirty}) => (
        <ChangeOrderStatusModal.Styled
          open={isOpen}
          disableEnforceFocus
          showCloseButton
          onClose={handleClose}
          onExited={handleExited}
        >
          <Modal.Header>{title}</Modal.Header>
          <Modal.Content>
            <p className='sub-title'>{subTitle}</p>
            <form onSubmit={handleSubmit}>
              <Field name='reason'>
                {({input, meta}) => (
                  <TextInput
                    label={reasonTitle}
                    value={input.value}
                    onChange={e => input.onChange(e.target.value)}
                    error={meta.error && meta.touched}
                    helperText={meta.touched && meta.error}
                    autoFocus
                    multiline={true}
                  />
                )}
              </Field>
              {canUploadAppraisal && (
                <UploadFormField
                  field='document'
                  label='Upload Appraisal (Optional)'
                  className='form-field'
                  optional={true}
                  opsOrderId={opsOrderId}
                />
              )}
            </form>
          </Modal.Content>
          <Modal.Footer>
            <Button variant='secondary' title='Cancel' size='large' onClick={handleClose} />
            <Button
              {...submitButton}
              type='submit'
              size='large'
              onClick={handleSubmit}
              disabled={isSubmitting}
            />
          </Modal.Footer>
        </ChangeOrderStatusModal.Styled>
      )}
    />
  )
}

ChangeOrderStatusModal.Styled = styled(Modal)`
  .header {
    padding-bottom: 16px;
  }

  p {
    margin: 0;
  }

  .sub-title {
    margin-bottom: 24px;
  }

  .portal-MuiInputBase-formControl {
    line-height: 22px;
    min-height: 85px;
    align-items: start;
  }

  .portal-MuiFormControl-root {
    margin-bottom: 24px;
    padding-bottom: 0;
  }

  .portal-MuiFormHelperText-root {
    position: relative;
    margin-top: 8.5px;
    line-height: 20px;
  }

  .portal-MuiInputLabel-shrink,
  .form-field-header {
    line-height: 20px;
    margin-bottom: 5px;
  }

  .form-field-header label {
    font-size: 0.75rem;
  }

  .form-field {
    background: unset;
    padding: 0;
  }
`

export default ChangeOrderStatusModal
